<nav class="bg-gray-800">
  <div class="max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="hidden sm:block sm:ml-6">
          <div class="flex space-x-4">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <a href="#" class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" aria-current="page">Descent FAQ</a>

            <a href="https://boardgamegeek.com/wiki/page/Descent_Second_Edition_Unofficial_FAQ" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Unofficial FAQ</a>

            <a href="https://descent-community.org/index.php/crrg/" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Community Reference Guide</a>

          </div>
        </div>
      </div>
    </div>
  </div>
</nav>