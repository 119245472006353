import { Expansion, Tier, Trait } from "./models";
export const monsters = [
    {
        name: "Barghest",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Action: Howl, Surge: +1 Heart",
            "Master: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Red, Abilities: Night Stalker, Action: Howl, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/barghest-bg-act1-back.png",
        xws: "barghest",
        traits: [Trait.WILDERNESS, Trait.DARK],
        ability_rules: [
            "Night Stalker: If attacked by any non-adjacent hero, this monster may add 1 brown die to its defense roll.",
            "Howl: Each hero within 3 spaces of this monster must test Willpower. Each hero that fails suffers 1 Fatigue.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Barghest",
    },
    {
        name: "Barghest",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Black, Attack: Blue Red, Abilities: Action: Howl, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 8, Defense: Black, Attack: Blue Red Yellow, Abilities: Night Stalker, Action: Howl, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/barghest-bg-act2-back.png",
        xws: "barghest",
        traits: [Trait.WILDERNESS, Trait.DARK],
        ability_rules: [
            "Night Stalker: If attacked by any non-adjacent hero, this monster may add 1 brown die to its defense roll.",
            "Howl: Each hero within 3 spaces of this monster must test Willpower. Each hero that fails suffers 1 Fatigue.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Barghest",
    },
    {
        name: "Cave Spider",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 3, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Poison, Surge: +1 Heart",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Web, Surge: Poison, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/cave-spider-bg-act1-back.png",
        xws: "cavespider",
        traits: [Trait.WILDERNESS, Trait.CAVE],
        ability_rules: [
            "Web: Each hero adjacent to this monster must suffer 1 Fatigue to move out of his current space; this is in addition to any other Fatigue suffered to move.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Cave_Spider",
    },
    {
        name: "Cave Spider",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Surge: Poison, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Web, Surge: Poison, Surge: +2 Hearts, Surge: +1 Heart",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/cave-spider-bg-act2-back.png",
        xws: "cavespider",
        traits: [Trait.WILDERNESS, Trait.CAVE],
        ability_rules: [
            "Web: Each hero adjacent to this monster must suffer 1 Fatigue to move out of his current space; this is in addition to any other Fatigue suffered to move.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Cave_Spider",
    },
    {
        name: "Elemental",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Black, Attack: Blue Red, Abilities: Surge: Fire, Surge: Earth, Surge: Water, Surge: Air",
            "Master: Speed: 4, Health: 6, Defense: Black, Attack: Blue Red, Abilities: Surge: Fire, Surge: Earth, Surge: Water, Surge: Air",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/elemental-bg-act1-back.png",
        xws: "elemental",
        traits: [Trait.COLD, Trait.HOT],
        ability_rules: [
            "Fire: Perform an attack targeting all figures adjacent to this monster. Each figure rolls defense dice separately.",
            "Earth: Each hero adjacent to this monster must test Awareness. Each hero that fails is Immobilized.",
            "Water: Each hero adjacent to this monster must test Willpower. Each hero that fails suffers 2 Fatigue.",
            "Air: Until the start of your next turn, this monster cannot be affected by any attacks, and heroes may move through it.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Elemental",
    },
    {
        name: "Elemental",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 8, Defense: Black Brown, Attack: Blue Yellow Red, Abilities: Surge: Fire, Surge: Earth, Surge: Water, Surge: Air",
            "Master: Speed: 4, Health: 10, Defense: Black Brown, Attack: Blue Yellow Red, Abilities: Surge: Fire, Surge: Earth, Surge: Water, Surge: Air",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/elemental-bg-act2-back.png",
        xws: "elemental",
        traits: [Trait.COLD, Trait.HOT],
        ability_rules: [
            "Fire: Perform an attack targeting all figures adjacent to this monster. Each figure rolls defense dice separately.",
            "Earth: Each hero adjacent to this monster must test Awareness. Each hero that fails is Immobilized.",
            "Water: Each hero adjacent to this monster must test Willpower. Each hero that fails suffers 2 Fatigue.",
            "Air: Until the start of your next turn, this monster cannot be affected by any attacks, and heroes may move through it.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Elemental",
    },
    {
        name: "Ettin",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray Gray, Attack: Blue Red, Abilities: Reach, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 8, Defense: Gray Gray, Attack: Blue Red, Abilities: Reach, Action: Throw, Surge: +3 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/ettin-bg-act1-back.png",
        xws: "ettin",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Throw: Choose a hero adjacent to this monster. That hero must test Might. If he fails, remove the hero from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space. Then the hero suffers 1 Heart.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ettin",
    },
    {
        name: "Ettin",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 7, Defense: Black Gray, Attack: Blue Red Red, Abilities: Reach, Surge: +1 Heart",
            "Master: Speed: 3, Health: 9, Defense: Black Gray, Attack: Blue Red Red, Abilities: Reach, Action: Throw, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/ettin-bg-act2-back.png",
        xws: "ettin",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Throw: Choose a hero adjacent to this monster. That hero must test Might. If he fails, remove the hero from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space. Then the hero suffers 1 Heart.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ettin",
    },
    {
        name: "Flesh Moulder",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Mend 1, Surge: +1 Heart",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Action: Heal, Surge: Mend 2, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/flesh-moulder-bg-act1-back.png",
        xws: "fleshmoulder",
        traits: [Trait.CURSED, Trait.CIVILIZED],
        ability_rules: [
            "Heal: Choose a monster within 3 spaces of this monster and roll 1 red power die. The chosen monster recovers Hearts equal to the Hearts rolled.",
            "Mend X: This monster recovers X Hearts.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Flesh_Moulder",
    },
    {
        name: "Flesh Moulder",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray Brown, Attack: Blue Yellow, Abilities: Surge: Mend 2, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 7, Defense: Gray Brown, Attack: Blue Yellow Yellow, Abilities: Action: Heal, Surge: Mend 3, Surge: +3 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/flesh-moulder-bg-act2-back.png",
        xws: "fleshmoulder",
        traits: [Trait.CURSED, Trait.CIVILIZED],
        ability_rules: [
            "Heal: Choose a monster within 3 spaces of this monster and roll 1 red power die. The chosen monster recovers Hearts equal to the Hearts rolled.",
            "Mend X: This monster recovers X Hearts.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Flesh_Moulder",
    },
    {
        name: "Goblin Archer",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 5, Health: 2, Defense: Gray, Attack: Blue Yellow, Abilities: Scamper, Cowardly, Surge: +1 Range, Surge: +1 Heart",
            "Master: Speed: 5, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Scamper, Surge: +2 Range, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/goblin-archer-bg-act1-back.png",
        xws: "goblinarcher",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Scamper: This monster may move through spaces containing heroes.",
            "Cowardly: This monster cannot spend surges on abilities unless it is within 3 spaces of any master monster or a lieutenant.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Goblin_Archer",
    },
    {
        name: "Goblin Archer",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Scamper, Cowardly, Surge: +2 Range, Surge: +2 Hearts",
            "Master: Speed: 5, Health: 6, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Scamper, Surge: +3 Range, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/goblin-archer-bg-act2-back.png",
        xws: "goblinarcher",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Scamper: This monster may move through spaces containing heroes.",
            "Cowardly: This monster cannot spend surges on abilities unless it is within 3 spaces of any master monster or a lieutenant.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Goblin_Archer",
    },
    {
        name: "Merriod",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Black, Attack: Blue Red, Abilities: Reach, Surge: Immobilize, Surge: +1 Heart",
            "Master: Speed: 3, Health: 7, Defense: Black, Attack: Blue Red, Abilities: Reach, Flail, Surge: Immobilize, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/merriod-bg-act1-back.png",
        xws: "merriod",
        traits: [Trait.WILDERNESS, Trait.WATER],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Flail: When attacking, this monster may target 2 separate heroes. This monster makes 1 attack roll and each hero rolls defense dice separately.",
            "Immobilize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Merriod",
    },
    {
        name: "Merriod",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 7, Defense: Gray Gray, Attack: Blue Red Yellow, Abilities: Reach, Surge: Immobilize, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 9, Defense: Gray Gray, Attack: Blue Red Yellow, Abilities: Reach, Flail, Surge: Immobilize, Surge: +3 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/merriod-bg-act2-back.png",
        xws: "merriod",
        traits: [Trait.WILDERNESS, Trait.WATER],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Flail: When attacking, this monster may target 2 separate heroes. This monster makes 1 attack roll and each hero rolls defense dice separately.",
            "Immobilize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Merriod",
    },
    {
        name: "Shadow Dragon",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 6, Defense: Gray Gray, Attack: Blue Red, Abilities: Shadow, Surge: +1 Heart",
            "Master: Speed: 3, Health: 9, Defense: Gray Gray, Attack: Blue Red, Abilities: Shadow, Surge: Fire Breath, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/shadow-dragon-bg-act1-back.png",
        xws: "shadowdragon",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Shadow: A hero adjacent to this monster that declares an attack must spend 1 Surge or the attack is considered a miss.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Shadow_Dragon",
    },
    {
        name: "Shadow Dragon",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Black Gray, Attack: Blue Red Red, Abilities: Shadow, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 10, Defense: Black Gray, Attack: Blue Red Red, Abilities: Shadow, Surge: Fire Breath, Surge: +3 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/shadow-dragon-bg-act2-back.png",
        xws: "shadowdragon",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Shadow: A hero adjacent to this monster that declares an attack must spend 1 Surge or the attack is considered a miss.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Shadow_Dragon",
    },
    {
        name: "Zombie",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 3, Defense: Brown, Attack: Blue Yellow, Abilities: Shambling, Surge: Disease, Surge: +1 Heart",
            "Master: Speed: 3, Health: 6, Defense: Brown, Attack: Blue Yellow, Abilities: Shambling, Action: Grab, Surge: Disease, Surge: +1 Heart",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/zombie-bg-act1-back.png",
        xws: "zombie",
        traits: [Trait.CURSED, Trait.BUILDING],
        ability_rules: [
            "Shambling: This monster may not perform more than 1 move action during a single turn.",
            "Grab: Choose a hero adjacent to this monster. The hero must test Might. If he fails, he is Immobilized.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Ynfernael_Hulk",
    },
    {
        name: "Zombie",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Brown, Attack: Blue Yellow, Abilities: Shambling, Surge: Disease, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 9, Defense: Brown, Attack: Blue Yellow Red, Abilities: Shambling, Action: Grab, Surge: Disease, Surge: +2 Hearts",
        ],
        expansion: Expansion.BASE_GAME,
        image: "monsters/zombie-bg-act2-back.png",
        xws: "zombie",
        traits: [Trait.CURSED, Trait.BUILDING],
        ability_rules: [
            "Shambling: This monster may not perform more than 1 move action during a single turn.",
            "Grab: Choose a hero adjacent to this monster. The hero must test Might. If he fails, he is Immobilized.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Ynfernael_Hulk",
    },
    {
        name: "Bane Spider (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Surge: Poison, Surge: Pierce 1",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red, Abilities: Action: Cocoon, Surge: Poison, Surge: Pierce 2",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/bane-spider-ck-act1-back.png",
        xws: "banespider",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Cocoon: Each hero adjacent to this monster must test Awareness. Each hero that fails is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Bane_Spider",
    },
    {
        name: "Bane Spider (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Gray Brown, Attack: Blue Red, Abilities: Surge: Poison, Surge: Pierce 2",
            "Master: Speed: 4, Health: 9, Defense: Gray Brown, Attack: Blue Red, Abilities: Action: Cocoon, Surge: Poison, Surge: Pierce 3",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/bane-spider-ck-act2-back.png",
        xws: "banespider",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Cocoon: Each hero adjacent to this monster must test Awareness. Each hero that fails is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Bane_Spider",
    },
    {
        name: "Beastman (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 2, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Surge: +1 Heart",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Command, Ravage, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/beastman-ck-act1-back.png",
        xws: "beastman",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Command: Each minion within 3 spaces of this monster may reroll 1 die on each of its attacks. Each minion may only benefit from one monster with Command at a time.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Beastman",
    },
    {
        name: "Beastman (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Surge: +2 Hearts",
            "Master: Speed: 5, Health: 6, Defense: Gray, Attack: Blue Red Yellow, Abilities: Command, Ravage, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/beastman-ck-act2-back.png",
        xws: "beastman",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Command: Each minion within 3 spaces of this monster may reroll 1 die on each of its attacks. Each minion may only benefit from one monster with Command at a time.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Beastman",
    },
    {
        name: "Blood Ape (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Surge: +1 Heart",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Action: Leap Attack, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/blood-ape-ck-act1-back.png",
        xws: "bloodape",
        traits: [Trait.CAVE, Trait.HOT],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Leap Attack: This monster may move its Speed, ignoring enemy figures as it does so. When if finishes its movement, perform a single attack that targets each figure this monster moved through. Each figure rolls defense dice separately. Limit once per turn.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Blood_Ape",
    },
    {
        name: "Blood Ape (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red Yellow, Abilities: Ravage, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 9, Defense: Gray, Attack: Blue Red Red, Abilities: Ravage, Action: Leap Attack, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/blood-ape-ck-act2-back.png",
        xws: "bloodape",
        traits: [Trait.CAVE, Trait.HOT],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Leap Attack: This monster may move its Speed, ignoring enemy figures as it does so. When if finishes its movement, perform a single attack that targets each figure this monster moved through. Each figure rolls defense dice separately. Limit once per turn.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Blood_Ape",
    },
    {
        name: "Chaos Beast (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray, Attack: Blank, Abilities: Morph, Surge: +1 Heart",
            "Master: Speed: 3, Health: 6, Defense: Gray, Attack: Blank, Abilities: Morph, Sorcery 2, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/chaos-beast-ck-act1-back.png",
        xws: "chaosbeast",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Morph: When this monster attacks, it uses the dice of a figure (overlord's choice) in its lind of sight. If a hero is chosen, the overlord may choose which of the hero's equipped weapons to use. The monster cannot use any of the figure's other abilities, just the dice.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Changeling",
    },
    {
        name: "Chaos Beast (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 7, Defense: Gray, Attack: Blank, Abilities: Morph, Surge: +1 Heart",
            "Master: Speed: 3, Health: 10, Defense: Gray, Attack: Blank, Abilities: Morph, Sorcery 3, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/chaos-beast-ck-act2-back.png",
        xws: "chaosbeast",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Morph: When this monster attacks, it uses the dice of a figure (overlord's choice) in its lind of sight. If a hero is chosen, the overlord may choose which of the hero's equipped weapons to use. The monster cannot use any of the figure's other abilities, just the dice.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Changeling",
    },
    {
        name: "Crypt Dragon (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Surge: Blast, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 7, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Action: Cause Fear, Surge: Blast, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/crypt-dragon-ck-act1-back.png",
        xws: "cryptdragon",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Cause Fear: Choose a hero adjacent to this monster. That hero must test Willpower. If he fails, he moves 2 spaces directly away from this monster and is Immobilized.",
            "Blast: This attack affects all figures adjacent to the target space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Crypt_Dragon",
    },
    {
        name: "Crypt Dragon (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 6, Defense: Black Gray, Attack: Blue Red Yellow, Abilities: Surge: Blast, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 10, Defense: Black Gray, Attack: Blue Red Yellow, Abilities: Action: Cause Fear, Surge: Blast, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/crypt-dragon-ck-act2-back.png",
        xws: "cryptdragon",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Cause Fear: Choose a hero adjacent to this monster. That hero must test Willpower. If he fails, he moves 2 spaces directly away from this monster and is Immobilized.",
            "Blast: This attack affects all figures adjacent to the target space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Crypt_Dragon",
    },
    {
        name: "Dark Priest (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 2, Defense: Brown, Attack: Blue Yellow, Abilities: Action: Dark Prayer, Surge: +1 Heart",
            "Master: Speed: 4, Health: 5, Defense: Brown, Attack: Blue Yellow, Abilities: Action: Dark Prayer, Action: Heal, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/dark-priest-ck-act1-back.png",
        xws: "darkpriest",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Dark Prayer: Each hero within 3 spaces of this monster must test Willpower. Each hero that fails suffers 1 Fatigue.",
            "Heal: Choose a monster within 3 spaces of this monster and roll 1 red power die. That monster recovers Hearts equal to the Hearts rolled.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Dark_Priest",
    },
    {
        name: "Dark Priest (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Brown, Attack: Blue Yellow, Abilities: Action: Dark Prayer, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 8, Defense: Brown, Attack: Blue Yellow Yellow, Abilities: Action: Dark Prayer, Action: Heal, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/dark-priest-ck-act2-back.png",
        xws: "darkpriest",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Dark Prayer: Each hero within 3 spaces of this monster must test Willpower. Each hero that fails suffers 1 Fatigue.",
            "Heal: Choose a monster within 3 spaces of this monster and roll 1 red power die. That monster recovers Hearts equal to the Hearts rolled.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Dark_Priest",
    },
    {
        name: "Deep Elf (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 7, Defense: Brown, Attack: Blue Yellow, Abilities: Stealthy, Pierce 2, Surge: +1 Heart",
            "Master: Speed: 5, Health: 9, Defense: Brown, Attack: Blue Yellow, Abilities: Stealthy, Pierce 4, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/deep-elf-ck-act1-back.png",
        xws: "deepelf",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Deep_Elf",
    },
    {
        name: "Deep Elf (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 8, Defense: Gray, Attack: Blue Yellow, Abilities: Stealthy, Pierce 2, Surge: +2 Hearts",
            "Master: Speed: 5, Health: 10, Defense: Gray, Attack: Blue Yellow, Abilities: Stealthy, Pierce 4, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/deep-elf-ck-act2-back.png",
        xws: "deepelf",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Deep_Elf",
    },
    {
        name: "Demon Lord (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 6, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Sorcery 2, Surge: Wither",
            "Master: Speed: 3, Health: 9, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Aura 1, Sorcery 3, Surge: Wither",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/demon-lord-ck-act1-back.png",
        xws: "demonlord",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Aura 1: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Heart.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Wither: The target suffers 1 Fatigue.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Demon_Lord",
    },
    {
        name: "Demon Lord (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Gray Gray, Attack: Blue Yellow Yellow, Abilities: Sorcery 2, Surge: Wither",
            "Master: Speed: 3, Health: 12, Defense: Gray Gray, Attack: Blue Red Yellow, Abilities: Aura 1, Sorcery 3, Surge: Wither",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/demon-lord-ck-act2-back.png",
        xws: "demonlord",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Aura 1: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Heart.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Wither: The target suffers 1 Fatigue.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Demon_Lord",
    },
    {
        name: "Ferrox (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Surge: Disease, Surge: Pierce 2",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Action: Leech, Surge: Disease, Surge: Pierce 2",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/ferrox-ck-act1-back.png",
        xws: "ferrox",
        traits: [Trait.CAVE, Trait.WATER],
        ability_rules: [
            "Leech: Choose a hero adjacent to this monster. That hero must test Might. If he fails, roll 1 yellow power die. The hero suffers 1 Fatigue per Heart rolled, and this monster recovers 1 Heart per Fatigue suffered.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ferrox",
    },
    {
        name: "Ferrox (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray Brown, Attack: Blue Red Yellow, Abilities: Surge: Disease, Surge: Pierce 3",
            "Master: Speed: 4, Health: 8, Defense: Gray Brown, Attack: Blue Red Yellow, Abilities: Action: Leech, Surge: Disease, Surge: Pierce 3",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/ferrox-ck-act2-back.png",
        xws: "ferrox",
        traits: [Trait.CAVE, Trait.WATER],
        ability_rules: [
            "Leech: Choose a hero adjacent to this monster. That hero must test Might. If he fails, roll 1 yellow power die. The hero suffers 1 Fatigue per Heart rolled, and this monster recovers 1 Heart per Fatigue suffered.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ferrox",
    },
    {
        name: "Giant (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 10, Defense: Black, Attack: Blue Red, Abilities: Reach, Surge: Stun",
            "Master: Speed: 3, Health: 12, Defense: Black, Attack: Blue Red, Abilities: Reach, Action: Sweep, Surge: Stun",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/giant-ck-act1-back.png",
        xws: "giant",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Sweep: Perform an attack. This attack affects each figure within range of this monster's attack. Each figure rolls defense dice separately.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Giant",
    },
    {
        name: "Giant (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 12, Defense: Black, Attack: Blue Red Yellow, Abilities: Reach, Surge: Stun",
            "Master: Speed: 3, Health: 15, Defense: Black, Attack: Blue Red Red, Abilities: Reach, Action: Sweep, Surge: Stun",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/giant-ck-act2-back.png",
        xws: "giant",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Sweep: Perform an attack. This attack affects each figure within range of this monster's attack. Each figure rolls defense dice separately.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Giant",
    },
    {
        name: "Golem (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Black, Attack: Blue Red, Abilities: Ironskin, Surge: +1 Heart",
            "Master: Speed: 3, Health: 10, Defense: Black, Attack: Blue Red, Abilities: Ironskin, Unmovable, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/golem-ck-act1-back.png",
        xws: "golem",
        traits: [Trait.MOUNTAIN, Trait.BUILDING],
        ability_rules: [
            "Ironskin: This monster is immune to Pierce and to all conditions.",
            "Unmovable: This monster may choose to ignore any game effect that would force it to move.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Golem",
    },
    {
        name: "Golem (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 10, Defense: Black Gray, Attack: Blue Red Red, Abilities: Ironskin, Surge: +1 Heart",
            "Master: Speed: 3, Health: 12, Defense: Black Gray, Attack: Blue Red Red, Abilities: Ironskin, Unmovable, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/golem-ck-act2-back.png",
        xws: "golem",
        traits: [Trait.MOUNTAIN, Trait.BUILDING],
        ability_rules: [
            "Ironskin: This monster is immune to Pierce and to all conditions.",
            "Unmovable: This monster may choose to ignore any game effect that would force it to move.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Golem",
    },
    {
        name: "Hellhound (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 3, Defense: Gray, Attack: Blue Red, Abilities: Surge: Fire Breath, Surge: Pierce 2",
            "Master: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Red, Abilities: Aura 1, Surge: Fire Breath, Surge: Pierce 2",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/hellhound-ck-act1-back.png",
        xws: "hellhound",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Aura 1: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Heart.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Hellhound",
    },
    {
        name: "Hellhound (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Surge: Fire Breath, Surge: Pierce 3",
            "Master: Speed: 5, Health: 8, Defense: Gray, Attack: Blue Red Yellow, Abilities: Aura 1, Surge: Fire Breath, Surge: Pierce 4",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/hellhound-ck-act2-back.png",
        xws: "hellhound",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Aura 1: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Heart.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Hellhound",
    },
    {
        name: "Ice Wyrm (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Gray Gray, Attack: Blue Red, Abilities: Reach, Freezing",
            "Master: Speed: 4, Health: 9, Defense: Gray Gray, Attack: Blue Red, Abilities: Reach, Freezing, Swallow",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/ice-wyrm-ck-act1-back.png",
        xws: "icewyrm",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Swallow: If a hero is defeated by this monster, remove his hero token from the map and place it on this monster's base. The hero cannot be revived until this monster is defeated, at which point his hero token is placed in one of this monster's spaces.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ice_Wyrm",
    },
    {
        name: "Ice Wyrm (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 11, Defense: Gray Gray, Attack: Blue Red Red, Abilities: Reach, Freezing",
            "Master: Speed: 4, Health: 14, Defense: Gray Gray, Attack: Blue Red Red, Abilities: Reach, Freezing, Swallow",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/ice-wyrm-ck-act2-back.png",
        xws: "icewyrm",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Swallow: If a hero is defeated by this monster, remove his hero token from the map and place it on this monster's base. The hero cannot be revived until this monster is defeated, at which point his hero token is placed in one of this monster's spaces.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ice_Wyrm",
    },
    {
        name: "Kobold (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 2, Defense: Brown, Attack: Blue, Abilities: Scamper, Surge: Swarm",
            "Master: Speed: 3, Health: 4, Defense: Brown, Attack: Blue, Abilities: Scamper, Split, Surge: Swarm",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/kobold-ck-act1-back.png",
        xws: "kobold",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Scamper: This monster may move through spaces containing heroes.",
            "Split: When this monster is defeated, replace it with 2 minions of the same type in the closest available empty spaces, ignoring group limits.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 3,1", "3 Heroes: 5,2", "4 Heroes: 6,3"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Kobold",
    },
    {
        name: "Kobold (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Brown, Attack: Blue, Abilities: Scamper, Surge: Swarm",
            "Master: Speed: 4, Health: 6, Defense: Brown, Attack: Blue Yellow, Abilities: Scamper, Split, Surge: Swarm",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/kobold-ck-act2-back.png",
        xws: "kobold",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Scamper: This monster may move through spaces containing heroes.",
            "Split: When this monster is defeated, replace it with 2 minions of the same type in the closest available empty spaces, ignoring group limits.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 3,1", "3 Heroes: 5,2", "4 Heroes: 6,3"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Kobold",
    },
    {
        name: "Lava Beetle (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 3, Defense: Gray, Attack: Blue Red, Abilities: Surge: Blast, Surge: +1 Heart",
            "Master: Speed: 3, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Blast, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/lava-beetle-ck-act1-back.png",
        xws: "lavabeetle",
        traits: [Trait.HOT, Trait.CAVE],
        ability_rules: [
            "Blast: This attack affects all figures adjacent to the target space. Master lava beetles do not need a Surge for this ability; their attacks may always have Blast.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Lava_Beetle",
    },
    {
        name: "Lava Beetle (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Surge: Blast, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 7, Defense: Gray, Attack: Blue Red Yellow, Abilities: Blast, Surge: +2 Hearts, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/lava-beetle-ck-act2-back.png",
        xws: "lavabeetle",
        traits: [Trait.HOT, Trait.CAVE],
        ability_rules: [
            "Blast: This attack affects all figures adjacent to the target space. Master lava beetles do not need a Surge for this ability; their attacks may always have Blast.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Lava_Beetle",
    },
    {
        name: "Manticore (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Ravage, Surge: Pierce 2, Surge: +1 Range",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Yellow, Abilities: Ravage, Surge: Pierce 3, Surge: Poison, Surge: +1 Range",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/manticore-ck-act1-back.png",
        xws: "manticore",
        traits: [Trait.WILDERNESS, Trait.DARK],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Manticore",
    },
    {
        name: "Manticore (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Ravage, Surge: Pierce 3, Surge: +2 Range",
            "Master: Speed: 4, Health: 9, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Ravage, Surge: Pierce 4, Surge: Poison, Surge: +2 Range",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/manticore-ck-act2-back.png",
        xws: "manticore",
        traits: [Trait.WILDERNESS, Trait.DARK],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Manticore",
    },
    {
        name: "Medusa (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Immobilize, Surge: Poison",
            "Master: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Immobilize, Surge: Poison, Surge: Stun",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/medusa-ck-act1-back.png",
        xws: "medusa",
        traits: [Trait.CURSED, Trait.BUILDING],
        ability_rules: [
            "Immobilize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Medusa",
    },
    {
        name: "Medusa (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Gray Brown, Attack: Blue Yellow Yellow, Abilities: Surge: Immobilize, Surge: Poison",
            "Master: Speed: 4, Health: 9, Defense: Gray BRown, Attack: Blue Yellow Yellow, Abilities: Surge: Immobilize, Surge: Poison, Surge: Stun",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/medusa-ck-act2-back.png",
        xws: "medusa",
        traits: [Trait.CURSED, Trait.BUILDING],
        ability_rules: [
            "Immobilize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Medusa",
    },
    {
        name: "Naga (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Black, Attack: Blue Red, Abilities: Sorcery 1, Action: Grab",
            "Master: Speed: 4, Health: 5, Defense: Black, Attack: Blue Red, Abilities: Sorcery 2, Action: Grab, Surge: Poison",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/naga-ck-act1-back.png",
        xws: "naga",
        traits: [Trait.WATER, Trait.CAVE],
        ability_rules: [
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Grab: Choose a hero adjacent to this monster. The hero must test Might. If he fails he is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Naga",
    },
    {
        name: "Naga (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Black, Attack: Blue Red, Abilities: Sorcery 3, Action: Grab",
            "Master: Speed: 4, Health: 7, Defense: Black, Attack: Blue Red Yellow, Abilities: Sorcery 3, Action: Grab, Surge: Poison",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/naga-ck-act2-back.png",
        xws: "naga",
        traits: [Trait.WATER, Trait.CAVE],
        ability_rules: [
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Grab: Choose a hero adjacent to this monster. The hero must test Might. If he fails he is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Naga",
    },
    {
        name: "Ogre (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 6, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Knockback, Surge: +3 Hearts",
            "Master: Speed: 3, Health: 8, Defense: Gray, Attack: Blue Red, Abilities: Undying, Surge: Knockback, Surge: +3 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/ogre-ck-act1-back.png",
        xws: "ogre",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Undying: When this monster is defeated, remove it from the map and then replace it with a minion of the same type, ignoring group limits.",
            "Knockback: Remove the target from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ogre",
    },
    {
        name: "Ogre (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 9, Defense: Gray, Attack: Blue Red, Abilities: Surge: Knockback, Surge: +3 Hearts",
            "Master: Speed: 3, Health: 12, Defense: Gray, Attack: Blue Red Yellow, Abilities: Undying, Surge: Knockback, Surge: +3 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/ogre-ck-act2-back.png",
        xws: "ogre",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Undying: When this monster is defeated, remove it from the map and then replace it with a minion of the same type, ignoring group limits.",
            "Knockback: Remove the target from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ogre",
    },
    {
        name: "Razorwing (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Brown, Attack: Blue Yellow, Abilities: Fly, Surge: +1 Heart",
            "Master: Speed: 6, Health: 6, Defense: Brown, Attack: Blue Yellow, Abilities: Fly, Surge: Stun, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/razorwing-ck-act1-back.png",
        xws: "razorwing",
        traits: [Trait.WILDERNESS, Trait.CAVE],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Razorwing",
    },
    {
        name: "Razorwing (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 7, Defense: Brown, Attack: Blue Yellow, Abilities: Fly, Surge: +2 Hearts",
            "Master: Speed: 6, Health: 9, Defense: Brown, Attack: Blue Yellow Yellow, Abilities: Fly, Surge: Stun, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/razorwing-ck-act2-back.png",
        xws: "razorwing",
        traits: [Trait.WILDERNESS, Trait.CAVE],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Razorwing",
    },
    {
        name: "Shade (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 2, Defense: Black, Attack: Blue Yellow, Abilities: Fly, Surge: Pierce 2",
            "Master: Speed: 4, Health: 4, Defense: Black, Attack: Blue Yellow, Abilities: Fly, Action: Leech, Surge: Pierce 2",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/shade-ck-act1-back.png",
        xws: "shade",
        traits: [Trait.CURSED, Trait.DARK],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Leech: Choose a hero adjacent to this monster. That hero must test Might. If he fails, roll 1 yellow power die. The hero suffers 1 Fatigue per Heart rolled, and this monster recovers 1 Heart per Fatigue suffered.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Shade",
    },
    {
        name: "Shade (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Black, Attack: Blue Red, Abilities: Fly, Surge: Pierce 2",
            "Master: Speed: 5, Health: 6, Defense: Black, Attack: Blue Red Yellow, Abilities: Fly, Action: Leech, Surge: Pierce 2",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/shade-ck-act2-back.png",
        xws: "shade",
        traits: [Trait.CURSED, Trait.DARK],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Leech: Choose a hero adjacent to this monster. That hero must test Might. If he fails, roll 1 yellow power die. The hero suffers 1 Fatigue per Heart rolled, and this monster recovers 1 Heart per Fatigue suffered.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Shade",
    },
    {
        name: "Skeleton Archer (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 2, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 1, Surge: +1 Range",
            "Master: Speed: 4, Health: 5, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 1, Undying, Surge: +1 Range, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/skeleton-archer-ck-act1-back.png",
        xws: "skeletonarcher",
        traits: [Trait.CURSED, Trait.CIVILIZED],
        ability_rules: [
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Undying: When this monster is defeated, remove it from the map and then replace it with a minion of the same type, ignoring group limits.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Skeleton_Archer",
    },
    {
        name: "Skeleton Archer (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 2, Surge: +1 Heart, Surge: +2 Range",
            "Master: Speed: 4, Health: 8, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 2, Undying, Surge: +2 Range, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/skeleton-archer-ck-act2-back.png",
        xws: "skeletonarcher",
        traits: [Trait.CURSED, Trait.CIVILIZED],
        ability_rules: [
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Undying: When this monster is defeated, remove it from the map and then replace it with a minion of the same type, ignoring group limits.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Skeleton_Archer",
    },
    {
        name: "Sorcerer (Conversion Kit)",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 3, Defense: Gray, Attack: Blue Yellow, Abilities: Sorcery 2, Action: Summon",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Sorcery 3, Undying, Action: Summon",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/sorcerer-ck-act1-back.png",
        xws: "sorcerer",
        traits: [Trait.CIVILIZED, Trait.BUILDING],
        ability_rules: [
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Undying: When this monster is defeated, remove it from the map and then replace it with a minion of the same type, ignoring group limits.",
            "Summon: Choose a minion within 3 spaces of this monster. Place that minion in an empty space adjacent to this monster.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Sorcerer",
    },
    {
        name: "Sorcerer (Conversion Kit)",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Sorcery 2, Action: Summon",
            "Master: Speed: 4, Health: 8, Defense: Gray, Attack: Blue Red, Abilities: Sorcery 3, Undying, Action: Summon",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/sorcerer-ck-act2-back.png",
        xws: "sorcerer",
        traits: [Trait.CIVILIZED, Trait.BUILDING],
        ability_rules: [
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Undying: When this monster is defeated, remove it from the map and then replace it with a minion of the same type, ignoring group limits.",
            "Summon: Choose a minion within 3 spaces of this monster. Place that minion in an empty space adjacent to this monster.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Sorcerer",
    },
    {
        name: "Troll (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Gray, Attack: Blue Red, Abilities: Reach, Action: Bash",
            "Master: Speed: 3, Health: 10, Defense: Gray, Attack: Blue Red, Abilities: Reach, Action: Bash, Action: Sweep",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/troll-ck-act1-back.png",
        xws: "troll",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Bash: Choose an adjacent hero. That hero must test Awareness. If he fails, he suffers 3 Hearts.",
            "Sweep: Perform an attack. This attack effects each figure within range of this monster's attack. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Troll",
    },
    {
        name: "Troll (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 10, Defense: Gray, Attack: Blue Red Red, Abilities: Reach, Action: Bash",
            "Master: Speed: 3, Health: 13, Defense: Gray, Attack: Blue Red Red, Abilities: Reach, Action: Bash, Action: Sweep",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/troll-ck-act2-back.png",
        xws: "troll",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Bash: Choose an adjacent hero. That hero must test Awareness. If he fails, he suffers 3 Hearts.",
            "Sweep: Perform an attack. This attack effects each figure within range of this monster's attack. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Troll",
    },
    {
        name: "Wendigo (Conversion Kit)",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Stealthy, Surge: +1 Heart",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red, Abilities: Freezing, Ravage, Stealthy, Surge: +1 Heart",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/wendigo-ck-act1-back.png",
        xws: "wendigo",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Wendigo",
    },
    {
        name: "Wendigo (Conversion Kit)",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Stealthy, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 10, Defense: Gray, Attack: Blue Red Yellow, Abilities: Freezing, Ravage, Stealthy, Surge: +2 Hearts",
        ],
        expansion: Expansion.CONVERSION_KIT,
        image: "monsters/wendigo-ck-act2-back.png",
        xws: "wendigo",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Wendigo",
    },
    {
        name: "Fire Imps",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 5, Health: 2, Defense: Gray, Attack: Blue Yellow, Abilities: Flame Fiend, Surge: +1 Range, Surge: Burn",
            "Master: Speed: 5, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Combustible, Flame Fiend, Surge: +1 Heart, Surge: Burn",
        ],
        expansion: Expansion.LAIR_OF_THE_WRYM,
        image: "monsters/fire-imps-lw-act1-back.png",
        xws: "fire-imps",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Combustible: When this monster is defeated, each hero adjacent to this monster suffers 1 Heart.",
            "Flame Fiend: This monster ignores all effects of lava. Each time this monster ends its turn in a space containing lava, it recovers 1 Heart. In addition, this monster never suffers Hearts from burning.",
            "Burn: If this attack deals at least 1 Heart (after the defense roll), the target is Burning.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 3,2"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Fire_Imps",
    },
    {
        name: "Fire Imps",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Flame Fiend, Surge: +1 Range, Surge: Burn",
            "Master: Speed: 5, Health: 6, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Combustible, Flame Fiend, Surge: +1 Heart, Surge: Burn",
        ],
        expansion: Expansion.LAIR_OF_THE_WRYM,
        image: "monsters/fire-imps-lw-act2-back.png",
        xws: "fire-imps",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Combustible: When this monster is defeated, each hero adjacent to this monster suffers 1 Heart.",
            "Flame Fiend: This monster ignores all effects of lava. Each time this monster ends its turn in a space containing lava, it recovers 1 Heart. In addition, this monster never suffers Hearts from burning.",
            "Burn: If this attack deals at least 1 Heart (after the defense roll), the target is Burning.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 3,2"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Fire_Imps",
    },
    {
        name: "Hybrid Sentinel",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Black, Attack: Blue Red, Abilities: Fly, Prey on the Weak, Surge: +1 Heart",
            "Master: Speed: 4, Health: 8, Defense: Black, Attack: Blue Red, Abilities: Fly, Prey on the Weak, Surge: Fire Breath, Surge: +1 Heart",
        ],
        expansion: Expansion.LAIR_OF_THE_WRYM,
        image: "monsters/hybrid-sentinel-lw-act1-back.png",
        xws: "Hybrid_Sentinel",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Prey on the Weak: Each of this monster's attacks targeting a hero with 2 or less Might gains +1 Heart.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Hybrid_Sentinel",
    },
    {
        name: "Hybrid Sentinel",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Black Brown, Attack: Blue Red, Abilities: Fly, Prey on the Weak, Surge: +1 Heart, Surge: +1 Heart",
            "Master: Speed: 4, Health: 9, Defense: Black Gray, Attack: Blue Yellow Red, Abilities: Fly, Prey on the Weak, Surge: Fire Breath, Surge: +2 Hearts",
        ],
        expansion: Expansion.LAIR_OF_THE_WRYM,
        image: "monsters/hybrid-sentinel-lw-act2-back.png",
        xws: "hybridsentinel",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Prey on the Weak: Each of this monster's attacks targeting a hero with 2 or less Might gains +1 Heart.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Hybrid_Sentinel",
    },
    {
        name: "Arachyura",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray Brown, Attack: Blue Red Green, Abilities: Action: Pincer Attack, Surge Surge: Pierce 1",
            "Master: Speed: 3, Health: 7, Defense: Gray Brown, Attack: Blue Red Green, Abilities: Action: Lingering Curse, Pincer Attack, Surge Surge: Pierce 2",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/arachyura-lr-act1-back.png",
        xws: "arachyura",
        traits: [Trait.WILDERNESS, Trait.CURSED],
        ability_rules: [
            "Lingering Curse: When this monster is defeated, each adjacent hero must test Willpower. Each hero that fails is Cursed.",
            "Pincer Attack: Perform an attack targeting up to 2 heroes adjacent to this monster. 1 attack roll is made but each hero rolls defense dice separately. Each target that suffers at least 1 Heart from this attack (after the defense roll) is Immobilized.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Arachyura",
    },
    {
        name: "Arachyura",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 7, Defense: Black, Attack: Blue Red Green, Abilities: Action: Pincer Attack, Surge Surge: Pierce 2",
            "Master: Speed: 3, Health: 9, Defense: Black, Attack: Blue Red Green, Abilities: Action: Lingering Curse, Pincer Attack, Surge Surge: Pierce 3",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/arachyura-lr-act2-back.png",
        xws: "arachyura",
        traits: [Trait.WILDERNESS, Trait.CURSED],
        ability_rules: [
            "Lingering Curse: When this monster is defeated, each adjacent hero must test Willpower. Each hero that fails is Cursed.",
            "Pincer Attack: Perform an attack targeting up to 2 heroes adjacent to this monster. 1 attack roll is made but each hero rolls defense dice separately. Each target that suffers at least 1 Heart from this attack (after the defense roll) is Immobilized.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Arachyura",
    },
    {
        name: "Carrion Drake",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 6, Defense: Gray, Attack: Blue Yellow, Abilities: Fly, Surge: Disease, Surge: +1 Heart",
            "Master: Speed: 5, Health: 8, Defense: Gray, Attack: Blue Yellow, Abilities: Fly, Plagued, Surge: Disease, Surge: +2 Hearts",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/carrion-drake-lr-act1-back.png",
        xws: "carriondrake",
        traits: [Trait.WATER, Trait.DARK],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Plagued: When a hero adjacent to this monster would discard a Poisoned or a Diseased Condition card, he instead keeps the card.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Broodwalker",
    },
    {
        name: "Carrion Drake",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 7, Defense: Gray Brown, Attack: Blue Yellow Green, Abilities: Fly, Surge: Disease, Surge: +1 Heart, Surge: +1 Heart",
            "Master: Speed: 5, Health: 10, Defense: Gray Gray, Attack: Blue Yellow Green, Abilities: Fly, Plagued, Surge: Disease, Surge: +2 Hearts",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/carrion-drake-lr-act2-back.png",
        xws: "carriondrake",
        traits: [Trait.WATER, Trait.DARK],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Plagued: When a hero adjacent to this monster would discard a Poisoned or a Diseased Condition card, he instead keeps the card.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Broodwalker",
    },
    {
        name: "Goblin Witcher",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 3, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Curse, Surge: +1 Range",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Action: Bewitch, Surge: Curse, Surge: +2 Range",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/goblin-witcher-lr-act1-back.png",
        xws: "goblinwitcher",
        traits: [Trait.BUILDING, Trait.CURSED],
        ability_rules: [
            "Bewitch: Move each adjacent Cursed hero up to 2 spaces in any direction of your choice.",
            "Curse: If this attack deals at least 1 Heart (after the defense roll), the target is Cursed.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Goblin_Witcher",
    },
    {
        name: "Goblin Witcher",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Curse, Surge: +2 Range, Surge: +1 Heart",
            "Master: Speed: 4, Health: 8, Defense: Gray, Attack: Blue Yellow Green, Abilities: Action: Bewitch, Surge: Curse, Surge: +2 Range, Surge: +2 Hearts",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/goblin-witcher-lr-act2-back.png",
        xws: "goblinwitcher",
        traits: [Trait.BUILDING, Trait.CURSED],
        ability_rules: [
            "Bewitch: Move each adjacent Cursed hero up to 2 spaces in any direction of your choice.",
            "Curse: If this attack deals at least 1 Heart (after the defense roll), the target is Cursed.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Goblin_Witcher",
    },
    {
        name: "Volucrix Reaver",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 3, Defense: Gray, Attack: Blue Yellow, Abilities: Action: Skirmish, Surge: Pierce 2",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Ravage, Action: Skirmish, Surge: Pierce 2, Surge: +1 Heart",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/volucrix-reaver-lr-act1-back.png",
        xws: "volucrixreaver",
        traits: [Trait.BUILDING, Trait.MOUNTAIN],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Skirmish: This monster may move 3 spaces, the perform an attack.",
            "Pierce 2: This attack ignores 2 Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Troll",
    },
    {
        name: "Volucrix Reaver",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray Brown, Attack: Blue Red, Abilities: Action: Skirmish, Surge: Pierce 2",
            "Master: Speed: 4, Health: 6, Defense: Gray Brown, Attack: Blue Red, Abilities: Ravage, Action: Skirmish, Surge: Pierce 3, Surge: +2 Hearts",
        ],
        expansion: Expansion.LABYRINTH_OF_RUIN,
        image: "monsters/volucrix-reaver-lr-act2-back.png",
        xws: "volucrixreaver",
        traits: [Trait.BUILDING, Trait.MOUNTAIN],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Skirmish: This monster may move 3 spaces, the perform an attack.",
            "Pierce 2: This attack ignores 2 Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Troll",
    },
    {
        name: "Harpy",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 3, Defense: Gray, Attack: Blue Green, Abilities: Fly, Surge: Swarm",
            "Master: Speed: 5, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Fly, Action: Flock, Surge: Swarm",
        ],
        expansion: Expansion.THE_TROLLFENS,
        image: "monsters/harpy-tf-act1-back.png",
        xws: "harpy",
        traits: [Trait.WILDERNESS, Trait.MOUNTAIN],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Flock: Each minion monster from this monster group within 5 spaces of this monster may immediately move 2 spaces.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Harpy",
    },
    {
        name: "Harpy",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Gray, Attack: Blue Yellow Green, Abilities: Fly, Surge: Swarm",
            "Master: Speed: 5, Health: 6, Defense: Gray, Attack: Blue Yellow Red, Abilities: Fly, Action: Flock, Surge: Swarm",
        ],
        expansion: Expansion.THE_TROLLFENS,
        image: "monsters/harpy-tf-act2-back.png",
        xws: "harpy",
        traits: [Trait.WILDERNESS, Trait.MOUNTAIN],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Flock: Each minion monster from this monster group within 5 spaces of this monster may immediately move 2 spaces.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Harpy",
    },
    {
        name: "Plague Worm",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 5, Defense: Gray Brown, Attack: Blue Red, Abilities: Action: Burrow, Surge: +1 Heart, Surge: Weaken",
            "Master: Speed: 2, Health: 7, Defense: Gray Brown, Attack: Blue Red, Abilities: Pestilence, Action: Burrow, Surge: +2 Hearts, Surge: Weaken",
        ],
        expansion: Expansion.THE_TROLLFENS,
        image: "monsters/plague-worm-tf-act1-back.png",
        xws: "plagueworm",
        traits: [Trait.WATER, Trait.CAVE],
        ability_rules: [
            "Pestilence: Each hero adjacent to this monster at the start of his turn must test Willpower. Each hero that fails is Diseased.",
            "Burrow: Remove this figure from the map and place it in an empty or occupied space up to 3 spaces away. Each figure occupying any target space is moved to the closest empty space of your choice and suffers 1 Fatigue. Limit once per monster per turn.",
            "Weaken: If this attack deals at least 1 Heart (after the defense roll), the target is Weakened.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Plague_Worm",
    },
    {
        name: "Plague Worm",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 6, Defense: Gray Gray, Attack: Blue Red Green, Abilities: Action: Burrow, Surge: +2 Hearts, Surge: Weaken",
            "Master: Speed: 2, Health: 9, Defense: Gray Gray, Attack: Blue Red Green, Abilities: Pestilence, Action: Burrow, Surge: +3 Hearts, Surge: Weaken",
        ],
        expansion: Expansion.THE_TROLLFENS,
        image: "monsters/plague-worm-tf-act2-back.png",
        xws: "plagueworm",
        traits: [Trait.WATER, Trait.CAVE],
        ability_rules: [
            "Pestilence: Each hero adjacent to this monster at the start of his turn must test Willpower. Each hero that fails is Diseased.",
            "Burrow: Remove this figure from the map and place it in an empty or occupied space up to 3 spaces away. Each figure occupying any target space is moved to the closest empty space of your choice and suffers 1 Fatigue. Limit once per monster per turn.",
            "Weaken: If this attack deals at least 1 Heart (after the defense roll), the target is Weakened.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Plague_Worm",
    },
    {
        name: "Changeling",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray Brown, Attack: Blue Red, Abilities: Action: Whisper, Surge: Wither, Surge: Bleed",
            "Master: Speed: 4, Health: 6, Defense: Gray Brown, Attack: Blue Red, Abilities: Hideous Laughter, Action: Whisper, Surge: Wither, Surge: Bleed",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/changeling-sn-act1-back.png",
        xws: "changeling",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Hideous Laughter: Each hero within 3 spaces of this monster applies -1 to his Might, Willpower, Knowledge, and Awareness (to a minimum of 1).",
            "Whisper: Each hero adjacent to this monster tests Willpower. Each hero who fails moves 1 space in the direction of your choice.",
            "Wither: The target suffers 1 Fatigue.",
            "Bleed: If this attack deals at least 1 Heart (after the defense roll), the target is Bleeding.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Changeling",
    },
    {
        name: "Changeling",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Black Brown, Attack: Blue Red, Abilities: Action: Whisper, Surge: Wither, Surge: Bleed",
            "Master: Speed: 4, Health: 8, Defense: Black Brown, Attack: Blue Red Yellow, Abilities: Hideous Laughter, Action: Whisper, Surge: Wither, Surge: Bleed",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/changeling-sn-act2-back.png",
        xws: "changeling",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Hideous Laughter: Each hero within 3 spaces of this monster applies -1 to his Might, Willpower, Knowledge, and Awareness (to a minimum of 1).",
            "Whisper: Each hero adjacent to this monster tests Willpower. Each hero who fails moves 1 space in the direction of your choice.",
            "Wither: The target suffers 1 Fatigue.",
            "Bleed: If this attack deals at least 1 Heart (after the defense roll), the target is Bleeding.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Changeling",
    },
    {
        name: "Ironbound",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 8, Defense: Gray Gray, Attack: Blue Red, Abilities: Reach, Ironskin, Protect",
            "Master: Speed: 2, Health: 10, Defense: Gray Gray, Attack: Blue Red, Abilities: Reach, Ironskin, Protect, Surge: +2 Hearts",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/ironbound-sn-act1-back.png",
        xws: "ironbound",
        traits: [Trait.CIVILIZED, Trait.BUILDING],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Ironskin: This monster is immune to Pierce and to all Conditions.",
            "Protect: Each time a figure in this monster's line of sight performs an attack targeting a figure adjacent to this monster, before dice are rolled, this monster may suffer 1 Heart to become the target of the attack. Range and line of sight are still measured to the original target space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ironbound",
    },
    {
        name: "Ironbound",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 10, Defense: Gray Black, Attack: Blue Red Green, Abilities: Reach, Ironskin, Protect, Surge Surge: +2 Hearts",
            "Master: Speed: 2, Health: 12, Defense: Gray Black, Attack: Blue Red Green, Abilities: Reach, Ironskin, Protect, Surge: +3 Hearts",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/ironbound-sn-act2-back.png",
        xws: "ironbound",
        traits: [Trait.CIVILIZED, Trait.BUILDING],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Ironskin: This monster is immune to Pierce and to all Conditions.",
            "Protect: Each time a figure in this monster's line of sight performs an attack targeting a figure adjacent to this monster, before dice are rolled, this monster may suffer 1 Heart to become the target of the attack. Range and line of sight are still measured to the original target space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ironbound",
    },
    {
        name: "Rat Swarm",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 4, Defense: Brown, Attack: Green, Abilities: Action: Merge, Action: Rend, Surge: Feast",
            "Master: Speed: 3, Health: 5, Defense: Brown, Attack: Green, Abilities: Ravenous, Action: Merge, Action: Rend, Surge: Feast",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/rat-swarm-sn-act1-back.png",
        xws: "ratswarm",
        traits: [Trait.BUILDING, Trait.DARK],
        ability_rules: [
            "Ravenous: When attacking a hero that is Bleeding, this monster adds 1 Surge to its attack results.",
            "Merge: Choose 1 monster adjacent to this monster of the same group. This monster suffers Hearts equal to its remaining health, and the chosen monster recovers an equal amount of Hearts.",
            "Rend: Choose a hero adjacent to this monster to test Might. If he fails, he is Bleeding.",
            "Feast: This attack gains +X Hearts, where X is equal to this monster's remaining Health.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Rat_Swarm",
    },
    {
        name: "Rat Swarm",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray, Attack: Green, Abilities: Action: Merge, Action: Rend, Surge: Feast",
            "Master: Speed: 3, Health: 6, Defense: Gray, Attack: Green, Abilities: Ravenous, Action: Merge, Action: Rend, Surge: Feast",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/rat-swarm-sn-act2-back.png",
        xws: "ratswarm",
        traits: [Trait.BUILDING, Trait.DARK],
        ability_rules: [
            "Ravenous: When attacking a hero that is Bleeding, this monster adds 1 Surge to its attack results.",
            "Merge: Choose 1 monster adjacent to this monster of the same group. This monster suffers Hearts equal to its remaining health, and the chosen monster recovers an equal amount of Hearts.",
            "Rend: Choose a hero adjacent to this monster to test Might. If he fails, he is Bleeding.",
            "Feast: This attack gains +X Hearts, where X is equal to this monster's remaining Health.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Rat_Swarm",
    },
    {
        name: "Ynfernael Hulk",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Gray, Attack: Blue Red, Abilities: Action: Bloodrush, Surge: Knockback",
            "Master: Speed: 3, Health: 9, Defense: Gray, Attack: Blue Red, Abilities: Action: Bloodrush, Surge: Charge, Surge: Knockback",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/ynfernael-hulk-sn-act1-back.png",
        xws: "ynfernaelhulk",
        traits: [Trait.CURSED, Trait.HOT],
        ability_rules: [
            "Bloodrush: This monster suffers 1 Heart and gains 5 movement points. Limit once per turn.",
            "Charge: If this monster was not adjacent to the target at the start of this turn, this attack gains +3 Hearts.",
            "Knockback: Remove the target from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ynfernael_Hulk",
    },
    {
        name: "Ynfernael Hulk",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 9, Defense: Black, Attack: Blue Red Yellow, Abilities: Action: Bloodrush, Surge: Knockback",
            "Master: Speed: 3, Health: 10, Defense: Black, Attack: Blue Red Yellow, Abilities: Action: Bloodrush, Surge: Charge, Surge: Knockback",
        ],
        expansion: Expansion.SHADOW_OF_NEREKHALL,
        image: "monsters/ynfernael-hulk-sn-act2-back.png",
        xws: "ynfernaelhulk",
        traits: [Trait.CURSED, Trait.HOT],
        ability_rules: [
            "Bloodrush: This monster suffers 1 Heart and gains 5 movement points. Limit once per turn.",
            "Charge: If this monster was not adjacent to the target at the start of this turn, this attack gains +3 Hearts.",
            "Knockback: Remove the target from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ynfernael_Hulk",
    },
    {
        name: "Bandit",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Brown, Attack: Blue Red, Abilities: Action: Pillage, Surge: Poison, Surge: +1 Heart",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Action: Pillage, Surge: Black Venom, Surge: +2 Hearts",
        ],
        expansion: Expansion.MANOR_OF_RAVENS,
        image: "monsters/bandit-mr-act1-back.png",
        xws: "bandit",
        traits: [Trait.WILDERNESS, Trait.BUILDING],
        ability_rules: [
            "Pillage: Perform an attack that targets an adjacent hero. If that hero is knocked out by this attack, choose 1 of his Search cards and shuffle it into the Search deck.",
            "Black Venom: If this attack deals at least 1 Heart (after the defense roll), the target is Doomed and Poisoned.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Bandit",
    },
    {
        name: "Bandit",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Red, Abilities: Action: Pillage, Surge: Poison, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 7, Defense: Black, Attack: Blue Yellow Yellow, Abilities: Action: Pillage, Surge: Black Venom, Surge: +2 Hearts",
        ],
        expansion: Expansion.MANOR_OF_RAVENS,
        image: "monsters/bandit-mr-act2-back.png",
        xws: "bandit",
        traits: [Trait.WILDERNESS, Trait.BUILDING],
        ability_rules: [
            "Pillage: Perform an attack that targets an adjacent hero. If that hero is knocked out by this attack, choose 1 of his Search cards and shuffle it into the Search deck.",
            "Black Venom: If this attack deals at least 1 Heart (after the defense roll), the target is Doomed and Poisoned.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Bandit",
    },
    {
        name: "Wraith",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Action: Death Cry, Surge: Doom, Surge: +1 Heart",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Yellow, Abilities: Reaper, Action: Death Cry, Surge: Doom, Surge: +2 Hearts",
        ],
        expansion: Expansion.MANOR_OF_RAVENS,
        image: "monsters/wraith-mr-act1-back.png",
        xws: "wraith",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Reaper: When a hero within 5 spaces of this monster is knocked out, this monster may immediately move up to its Speed and then perform an attack. Limit once per round.",
            "Death Cry: Choose 1 hero within 3 spaces of this monster. That hero tests Willpower. If he fails, he is Doomed or suffers 1 Heart, your choice. Limit once per round.",
            "Doom: If this attack deals at least 1 Heart (after the defense roll), the target is Doomed.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Wraith",
    },
    {
        name: "Wraith",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 5, Health: 6, Defense: Black, Attack: Blue Yellow, Abilities: Action: Death Cry, Surge: Doom, Surge: +2 Hearts",
            "Master: Speed: 5, Health: 8, Defense: Black, Attack: Blue Red Yellow, Abilities: Reaper, Action: Death Cry, Surge: Doom, Surge: +3 Hearts",
        ],
        expansion: Expansion.MANOR_OF_RAVENS,
        image: "monsters/wraith-mr-act2-back.png",
        xws: "wraith",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Reaper: When a hero within 5 spaces of this monster is knocked out, this monster may immediately move up to its Speed and then perform an attack. Limit once per round.",
            "Death Cry: Choose 1 hero within 3 spaces of this monster. That hero tests Willpower. If he fails, he is Doomed or suffers 1 Heart, your choice. Limit once per round.",
            "Doom: If this attack deals at least 1 Heart (after the defense roll), the target is Doomed.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Wraith",
    },
    {
        name: "Bone Horror",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Extend, Lithesome, Surge: Pierce 1",
            "Master: Speed: 5, Health: 7, Defense: Gray, Attack: Blue Yellow, Abilities: Extend, Lithesome, Surge: Lash, Surge: Pierce 2",
        ],
        expansion: Expansion.MISTS_OF_BILEHALL,
        image: "monsters/bone-horror-mb-act1-back.png",
        xws: "bonehorror",
        traits: [Trait.CAVE, Trait.CURSED],
        ability_rules: [
            "Extend: Each time this monster performs an attack, it may target a figure up to 3 spaces away and in its line of sight.",
            "Lithesome: Friendly figures do not block this monster's line of sight.",
            "Lash: After this attack resolves, place the target in an empty space up to 2 spaces away from this monster.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Bone_Horror",
    },
    {
        name: "Bone Horror",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 6, Defense: Black, Attack: Blue Red, Abilities: Extend, Lithesome, Surge: Pierce 1",
            "Master: Speed: 5, Health: 9, Defense: Black, Attack: Blue Red Yellow, Abilities: Extend, Lithesome, Surge: Lash, Surge: Pierce 2",
        ],
        expansion: Expansion.MISTS_OF_BILEHALL,
        image: "monsters/bone-horror-mb-act2-back.png",
        xws: "bonehorror",
        traits: [Trait.CAVE, Trait.CURSED],
        ability_rules: [
            "Extend: Each time this monster performs an attack, it may target a figure up to 3 spaces away and in its line of sight.",
            "Lithesome: Friendly figures do not block this monster's line of sight.",
            "Lash: After this attack resolves, place the target in an empty space up to 2 spaces away from this monster.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Bone_Horror",
    },
    {
        name: "Broodwalker",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 7, Defense: Brown, Attack: Blue Yellow, Abilities: Overflowing, Surge: Terrify, Surge: +1 Heart",
            "Master: Speed: 2, Health: 10, Defense: Brown, Attack: Blue Yellow, Abilities: Hive Defense, Overflowing, Surge: +1 Heart, Surge: Colonize",
        ],
        expansion: Expansion.MISTS_OF_BILEHALL,
        image: "monsters/broodwalker-mb-act1-back.png",
        xws: "broodwalker",
        traits: [Trait.DARK, Trait.BUILDING],
        ability_rules: [
            "Hive Defense: Heroes treat each space adjacent to this monster as a Hazard space.",
            "Overflowing: Heroes treat each space adjacent to this monster as a Sludge space.",
            "Colonize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized and Terrified.",
            "Terrify: If this attack deals at least 1 Heart (after the defense roll), the target is Terrified.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Broodwalker",
    },
    {
        name: "Broodwalker",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 8, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Overflowing, Surge: Terrify, Surge: +1 Heart",
            "Master: Speed: 2, Health: 12, Defense: Gray, Attack: Blue Red Yellow, Abilities: Hive Defense, Overflowing, Surge: +1 Heart, Surge: Colonize",
        ],
        expansion: Expansion.MISTS_OF_BILEHALL,
        image: "monsters/broodwalker-mb-act2-back.png",
        xws: "broodwalker",
        traits: [Trait.DARK, Trait.BUILDING],
        ability_rules: [
            "Hive Defense: Heroes treat each space adjacent to this monster as a Hazard space.",
            "Overflowing: Heroes treat each space adjacent to this monster as a Sludge space.",
            "Colonize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized and Terrified.",
            "Terrify: If this attack deals at least 1 Heart (after the defense roll), the target is Terrified.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Broodwalker",
    },
    {
        name: "Reanimate",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 3, Defense: Brown, Attack: Blue, Abilities: Phalanx, Reanimation, Surge: Swarm",
            "Master: Speed: 3, Health: 5, Defense: Brown, Attack: Blue Red, Abilities: Phalanx, Reanimation, Action: Maneuver, Surge: Swarm",
        ],
        expansion: Expansion.MISTS_OF_BILEHALL,
        image: "monsters/reanimate-mb-act1-back.png",
        xws: "reanimate",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Phalanx: If this monster is adjacent to a figure from its monster group, replace its brown defense die with 1 gray defense die.",
            "Reanimation: Each time this monster suffers Hearts and is not defeated, it recovers Hearts equal to either the amount suffered or the number of monsters from this monster group within 3 spaces of it, whichever is less.",
            "Maneuver: Choose 1 minion monster adjacent to this monster. That monster gains 2 movement points.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 3,1", "3 Heroes: 3,2", "4 Heroes: 4,2"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Reanimate_(monster)",
    },
    {
        name: "Reanimate",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Brown, Attack: Yellow, Abilities: Phalanx, Reanimation, Surge: Swarm",
            "Master: Speed: 3, Health: 8, Defense: Brown, Attack: Blue Yellow Yellow, Abilities: Phalanx, Reanimation, Action: Maneuver, Surge: Swarm",
        ],
        expansion: Expansion.MISTS_OF_BILEHALL,
        image: "monsters/reanimate-mb-act2-back.png",
        xws: "reanimate",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Phalanx: If this monster is adjacent to a figure from its monster group, replace its brown defense die with 1 gray defense die.",
            "Reanimation: Each time this monster suffers Hearts and is not defeated, it recovers Hearts equal to either the amount suffered or the number of monsters from this monster group within 3 spaces of it, whichever is less.",
            "Maneuver: Choose 1 minion monster adjacent to this monster. That monster gains 2 movement points.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 3,1", "3 Heroes: 3,2", "4 Heroes: 4,2"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Reanimate_(monster)",
    },
    {
        name: "Marrow Priest",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 7, Defense: Gray Brown, Attack: Blue Yellow, Abilities: Shadow Step, Surge: Mortal Binding, Surge: +1 Heart",
            "Master: Speed: 5, Health: 9, Defense: Gray Brown, Attack: Blue Yellow, Abilities: Shadow Step, Surge: Mortal Binding, Surge: +1 Heart",
        ],
        expansion: Expansion.THE_CHAINS_THAT_RUST,
        image: "monsters/marrow-priest-cr-act1-back.png",
        xws: "marrowpriest",
        traits: [Trait.DARK, Trait.BUILDING],
        ability_rules: [
            "Shadow Step: Each time a hero performs an attack that targets this monster, he may spend 1 Surge. If he does not, this monster gains 5 movement points after the attack resolves.",
            "Mortal Binding: The target tests Knowledge. If he fails, place his hero token on this card. A hero whose token is on this card cannot recover Hearts by any means. When a monster from this group is defeated or a hero is knocked out, discard all hero tokens from this card.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Marrow_Priest",
    },
    {
        name: "Marrow Priest",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 8, Defense: Gray Gray, Attack: Blue Yellow Yellow, Abilities: Shadow Step, Surge: Mortal Binding, Surge: +1 Heart",
            "Master: Speed: 5, Health: 10, Defense: Gray Gray, Attack: Blue Yellow Yellow, Abilities: Shadow Step, Surge: Mortal Binding, Surge: +1 Heart",
        ],
        expansion: Expansion.THE_CHAINS_THAT_RUST,
        image: "monsters/marrow-priest-cr-act2-back.png",
        xws: "marrowpriest",
        traits: [Trait.DARK, Trait.BUILDING],
        ability_rules: [
            "Shadow Step: Each time a hero performs an attack that targets this monster, he may spend 1 Surge. If he does not, this monster gains 5 movement points after the attack resolves.",
            "Mortal Binding: The target tests Knowledge. If he fails, place his hero token on this card. A hero whose token is on this card cannot recover Hearts by any means. When a monster from this group is defeated or a hero is knocked out, discard all hero tokens from this card.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Marrow_Priest",
    },
    {
        name: "Shambling Colossus",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Black, Attack: Blue Red, Abilities: Puncture, Surge: +1 Heart",
            "Master: Speed: 3, Health: 8, Defense: Black, Attack: Blue Red, Abilities: Harrowing, Puncture, Surge: +1 Heart",
        ],
        expansion: Expansion.THE_CHAINS_THAT_RUST,
        image: "monsters/shambling-colossus-cr-act1-back.png",
        xws: "shamblingcolossus",
        traits: [Trait.WILDERNESS, Trait.CURSED],
        ability_rules: [
            "Harrowing: Each time a hero within 3 spaces of this monster performs an attack that targets this monster, before rolling dice, that hero tests Willpower. If he fails, he is Terrified.",
            "Puncture: Each of this monster's attacks ignored 1 Shield for each defense die rolled by the target of that attack.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Shambling_Colossus",
    },
    {
        name: "Shambling Colossus",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 7, Defense: Black, Attack: Blue Red, Abilities: Puncture, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 9, Defense: Black, Attack: Blue Red Yellow, Abilities: Harrowing, Puncture, Surge: +2 Hearts",
        ],
        expansion: Expansion.THE_CHAINS_THAT_RUST,
        image: "monsters/shambling-colossus-cr-act2-back.png",
        xws: "shamblingcolossus",
        traits: [Trait.WILDERNESS, Trait.CURSED],
        ability_rules: [
            "Harrowing: Each time a hero within 3 spaces of this monster performs an attack that targets this monster, before rolling dice, that hero tests Willpower. If he fails, he is Terrified.",
            "Puncture: Each of this monster's attacks ignored 1 Shield for each defense die rolled by the target of that attack.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Shambling_Colossus",
    },
    {
        name: "The Dispossessed",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 6, Defense: Gray, Attack: Blue Yellow, Abilities: Ethereal Hold, Surge: Fearbrand, Surge: +1 Heart",
            "Master: Speed: 2, Health: 8, Defense: Gray, Attack: Blue Yellow, Abilities: Ethereal Hold, Surge: Fearbrand, Surge: Terrify, Surge: +1 Heart",
        ],
        expansion: Expansion.THE_CHAINS_THAT_RUST,
        image: "monsters/the-dispossessed-cr-act1-back.png",
        xws: "thedispossessed",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Ethereal Hold: At the start of the overlord's turn, this monster may discard 1 hero token from its base. If it does, remove it from the map and place it within 3 spaces of the corresponding hero.",
            "Fearbrand: Choose a hero in this monster's line of sight and place that hero's hero token on this monster's base.",
            "Terrify: If this attack deals at least 1 Heart (after the defense roll), the target is Terrified.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/The_Dispossessed",
    },
    {
        name: "The Dispossessed",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 2, Health: 8, Defense: Gray Brown, Attack: Blue Yellow, Abilities: Ethereal Hold, Surge: Fearbrand, Surge: +2 Hearts",
            "Master: Speed: 2, Health: 10, Defense: Gray Brown, Attack: Blue Yellow Yellow, Abilities: Ethereal Hold, Surge: Fearbrand, Surge: Terrify, Surge: +2 Hearts",
        ],
        expansion: Expansion.THE_CHAINS_THAT_RUST,
        image: "monsters/the-dispossessed-cr-act2-back.png",
        xws: "thedispossessed",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Ethereal Hold: At the start of the overlord's turn, this monster may discard 1 hero token from its base. If it does, remove it from the map and place it within 3 spaces of the corresponding hero.",
            "Fearbrand: Choose a hero in this monster's line of sight and place that hero's hero token on this monster's base.",
            "Terrify: If this attack deals at least 1 Heart (after the defense roll), the target is Terrified.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/The_Dispossessed",
    },
    {
        name: "Bane Spider",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Surge: Poison, Surge: Pierce 1",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red, Abilities: Action: Cocoon, Surge: Poison, Surge: Pierce 2",
        ],
        expansion: Expansion.OATH_OF_THE_OUTCAST,
        image: "monsters/bane-spider-oo-act1-back.png",
        xws: "Bane_Spider",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Cocoon: Each hero adjacent to this monster must test Awareness. Each hero who fails is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Bane_Spider",
    },
    {
        name: "Bane Spider",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Gray Brown, Attack: Blue Red, Abilities: Surge: Poison, Surge: Pierce 2",
            "Master: Speed: 4, Health: 9, Defense: Gray Brown, Attack: Blue Red Yellow, Abilities: Action: Cocoon, Surge: Poison, Surge: Pierce 3",
        ],
        expansion: Expansion.OATH_OF_THE_OUTCAST,
        image: "monsters/bane-spider-oo-act2-back.png",
        xws: "banespider",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Cocoon: Each hero adjacent to this monster must test Awareness. Each hero that fails is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Bane_Spider",
    },
    {
        name: "Beastman",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Ravage, Surge: +1 Heart",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Command, Ravage, Surge: +2 Hearts",
        ],
        expansion: Expansion.OATH_OF_THE_OUTCAST,
        image: "monsters/beastman-oo-act1-back.png",
        xws: "beastman",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Command: Each minion within 3 spaces of this monster may reroll 1 die on each of its attacks. Each minion can only benefit from 1 monster with Command during each of its attacks.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Beastman",
    },
    {
        name: "Beastman",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Surge: +2 Hearts",
            "Master: Speed: 5, Health: 6, Defense: Gray, Attack: Blue Red Yellow, Abilities: Command, Ravage, Surge: +2 Hearts",
        ],
        expansion: Expansion.OATH_OF_THE_OUTCAST,
        image: "monsters/beastman-oo-act2-back.png",
        xws: "beastman",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Command: Each minion within 3 spaces of this monster may reroll 1 die on each of its attacks. Each minion can only benefit from 1 monster with Command during each of its attacks.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Beastman",
    },
    {
        name: "Razorwing",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Brown, Attack: Blue Yellow, Abilities: Fly, Surge: +1 Heart",
            "Master: Speed: 6, Health: 6, Defense: Brown, Attack: Blue Yellow, Abilities: Fly, Surge: Stun, Surge: +1 Heart",
        ],
        expansion: Expansion.OATH_OF_THE_OUTCAST,
        image: "monsters/razorwing-oo-act1-back.png",
        xws: "razorwing",
        traits: [Trait.WILDERNESS, Trait.CAVE],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Razorwing",
    },
    {
        name: "Razorwing",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 7, Defense: Brown, Attack: Blue Yellow, Abilities: Fly, Surge: +2 Hearts",
            "Master: Speed: 6, Health: 9, Defense: Brown, Attack: Blue Yellow Yellow, Abilities: Fly, Surge: Stun, Surge: +2 Hearts",
        ],
        expansion: Expansion.OATH_OF_THE_OUTCAST,
        image: "monsters/razorwing-oo-act2-back.png",
        xws: "razorwing",
        traits: [Trait.WILDERNESS, Trait.CAVE],
        ability_rules: [
            "Fly: This monster may ignore enemy figures and the effects of terrain while moving. It must end its movement in an empty space following normal movement rules.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Razorwing",
    },
    {
        name: "Chaos Beast",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray, Attack: Blank, Abilities: Morph, Surge: +1 Heart",
            "Master: Speed: 3, Health: 6, Defense: Gray, Attack: Blank, Abilities: Morph, Sorcery 2, Surge: +1 Heart",
        ],
        expansion: Expansion.CROWN_OF_DESTINY,
        image: "monsters/chaos-beast-cd-act1-back.png",
        xws: "chaosbeast",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Morph: When this monster attacks, it uses the dice of a figure (overlord's choice) in its lind of sight. If a hero is chosen, the overlord may choose which of the hero's equipped weapons to use. The monster cannot use any of the figure's other abilities.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Changeling",
    },
    {
        name: "Chaos Beast",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 7, Defense: Gray, Attack: Blank, Abilities: Morph, Surge: +1 Heart",
            "Master: Speed: 3, Health: 10, Defense: Gray, Attack: Blank, Abilities: Morph, Sorcery 3, Surge: +1 Heart",
        ],
        expansion: Expansion.CROWN_OF_DESTINY,
        image: "monsters/chaos-beast-cd-act2-back.png",
        xws: "chaosbeast",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Morph: When this monster attacks, it uses the dice of a figure (overlord's choice) in its lind of sight. If a hero is chosen, the overlord may choose which of the hero's equipped weapons to use. The monster cannot use any of the figure's other abilities.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Changeling",
    },
    {
        name: "Giant",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 10, Defense: Black, Attack: Blue Red, Abilities: Reach, Surge: Stun",
            "Master: Speed: 3, Health: 12, Defense: Black, Attack: Blue Red, Abilities: Reach, Action: Sweep, Surge: Stun",
        ],
        expansion: Expansion.CROWN_OF_DESTINY,
        image: "monsters/giant-cd-act1-back.png",
        xws: "giant",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Sweep: Perform an attack. This attack affects each figure within two spaces and line of sight of this monster. Each figure rolls defense dice separately.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Giant",
    },
    {
        name: "Giant",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 12, Defense: Black, Attack: Blue Red Yellow, Abilities: Reach, Surge: Stun",
            "Master: Speed: 3, Health: 15, Defense: Black, Attack: Blue Red Red, Abilities: Reach, Action: Sweep, Surge: Stun",
        ],
        expansion: Expansion.CROWN_OF_DESTINY,
        image: "monsters/giant-cd-act2-back.png",
        xws: "giant",
        traits: [Trait.MOUNTAIN, Trait.WILDERNESS],
        ability_rules: [
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Sweep: Perform an attack. This attack affects each figure within two spaces and line of sight of this monster. Each figure rolls defense dice separately.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Giant",
    },
    {
        name: "Lava Beetle",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 3, Defense: Gray, Attack: Blue Red, Abilities: Surge: Blast, Surge: +1 Heart",
            "Master: Speed: 3, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Blast, Surge: +1 Heart",
        ],
        expansion: Expansion.CROWN_OF_DESTINY,
        image: "monsters/lava-beetle-cd-act1-back.png",
        xws: "lavabeetle",
        traits: [Trait.HOT, Trait.CAVE],
        ability_rules: [
            "Blast: This attack affects all figures adjacent to the target space.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Lava_Beetle",
    },
    {
        name: "Lava Beetle",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Surge: Blast, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 7, Defense: Gray, Attack: Blue Red Yellow, Abilities: Blast, Surge: +2 Hearts, Surge: +1 Heart",
        ],
        expansion: Expansion.CROWN_OF_DESTINY,
        image: "monsters/lava-beetle-cd-act2-back.png",
        xws: "lavabeetle",
        traits: [Trait.HOT, Trait.CAVE],
        ability_rules: [
            "Blast: This attack affects all figures adjacent to the target space.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Lava_Beetle",
    },
    {
        name: "Golem",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Black, Attack: Blue Red, Abilities: Ironskin, Surge: +1 Heart",
            "Master: Speed: 3, Health: 10, Defense: Black, Attack: Blue Red, Abilities: Ironskin, Unmovable, Surge: +2 Hearts",
        ],
        expansion: Expansion.CRUSADE_OF_THE_FORGOTTEN,
        image: "monsters/golem-cf-act1-back.png",
        xws: "golem",
        traits: [Trait.MOUNTAIN, Trait.BUILDING],
        ability_rules: [
            "Ironskin: This monster is immune to Pierce and to all conditions.",
            "Unmovable: This monster may choose to ignore any game effect that would force it to move.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Golem",
    },
    {
        name: "Golem",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 10, Defense: Black Gray, Attack: Blue Red Red, Abilities: Ironskin, Surge: +1 Heart",
            "Master: Speed: 3, Health: 12, Defense: Black Gray, Attack: Blue Red Red, Abilities: Ironskin, Unmovable, Surge: +2 Hearts",
        ],
        expansion: Expansion.CRUSADE_OF_THE_FORGOTTEN,
        image: "monsters/golem-cf-act2-back.png",
        xws: "golem",
        traits: [Trait.MOUNTAIN, Trait.BUILDING],
        ability_rules: [
            "Ironskin: This monster is immune to Pierce and to all conditions.",
            "Unmovable: This monster may choose to ignore any game effect that would force it to move.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Golem",
    },
    {
        name: "Medusa",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Immobilize, Surge: Poison",
            "Master: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Immobilize, Surge: Poison, Surge: Stun",
        ],
        expansion: Expansion.CRUSADE_OF_THE_FORGOTTEN,
        image: "monsters/medusa-cf-act1-back.png",
        xws: "medusa",
        traits: [Trait.CURSED, Trait.BUILDING],
        ability_rules: [
            "Immobilize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Medusa",
    },
    {
        name: "Medusa",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Gray Brown, Attack: Blue Yellow Yellow, Abilities: Surge: Immobilize, Surge: Poison",
            "Master: Speed: 4, Health: 9, Defense: Gray Brown, Attack: Blue Yellow Yellow, Abilities: Surge: Immobilize, Surge: Poison, Surge: Stun",
        ],
        expansion: Expansion.CRUSADE_OF_THE_FORGOTTEN,
        image: "monsters/medusa-cf-act2-back.png",
        xws: "medusa",
        traits: [Trait.CURSED, Trait.BUILDING],
        ability_rules: [
            "Immobilize: If this attack deals at least 1 Heart (after the defense roll), the target is Immobilized.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
            "Stun: If this attack deals at least 1 Heart (after the defense roll), the target is Stunned.",
        ],
        group_size: ["2 Heroes: 2,0", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Medusa",
    },
    {
        name: "Sorcerer",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 3, Defense: Gray, Attack: Blue Yellow, Abilities: Sorcery 2, Action: Summon",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Death Wish, Sorcery 3, Action: Summon",
        ],
        expansion: Expansion.CRUSADE_OF_THE_FORGOTTEN,
        image: "monsters/sorcerer-cf-act1-back.png",
        xws: "sorcerer",
        traits: [Trait.CIVILIZED, Trait.BUILDING],
        ability_rules: [
            "Death Wish: When this master monster would be defeated, the overlord may choose 1 minion monster of the same group to be defeated instead. If he does, this master monster recovers all Hearts.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Summon: Choose a minion monster within 3 spaces of this monster. Place that minion monster in an empty space adjacent to this monster.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Sorcerer",
    },
    {
        name: "Sorcerer",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Sorcery 2, Action: Summon",
            "Master: Speed: 4, Health: 8, Defense: Gray, Attack: Blue Red, Abilities: Death Wish, Sorcery 3, Action: Summon",
        ],
        expansion: Expansion.CRUSADE_OF_THE_FORGOTTEN,
        image: "monsters/sorcerer-cf-act2-back.png",
        xws: "sorcerer",
        traits: [Trait.CIVILIZED, Trait.BUILDING],
        ability_rules: [
            "Death Wish: When this master monster would be defeated, the overlord may choose 1 minion monster of the same group to be defeated instead. If he does, this master monster recovers all Hearts.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Summon: Choose a minion monster within 3 spaces of this monster. Place that minion monster in an empty space adjacent to this monster.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Sorcerer",
    },
    {
        name: "Crypt Dragon",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 5, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Surge: Blast, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 7, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Action: Cause Fear, Surge: Blast, Surge: +2 Hearts",
        ],
        expansion: Expansion.GUARDIANS_OF_DEEPHALL,
        image: "monsters/crypt-dragon-gd-act1-back.png",
        xws: "cryptdragon",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Cause Fear: Choose a hero adjacent to this monster. That hero must test Willpower. If he fails, he moves 2 spaces directly away from this monster and is Immobilized.",
            "Blast: This attack affects all figures adjacent to the target space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Crypt_Dragon",
    },
    {
        name: "Crypt Dragon",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 7, Defense: Black Gray, Attack: Blue Red Yellow, Abilities: Horrifying, Surge: Blast, Surge: +2 Hearts",
            "Master: Speed: 3, Health: 10, Defense: Black Gray, Attack: Blue Red Yellow, Abilities: Horrifying, Action: Cause Fear, Surge: Blast, Surge: +2 Hearts",
        ],
        expansion: Expansion.GUARDIANS_OF_DEEPHALL,
        image: "monsters/crypt-dragon-gd-act2-back.png",
        xws: "cryptdragon",
        traits: [Trait.DARK, Trait.CURSED],
        ability_rules: [
            "Horrifying: Each hero adjacent to 1 or more monsters with Horrifying applies -1 to his Willpower (to a minimum of 1).",
            "Cause Fear: Choose a hero adjacent to this monster. That hero must test Willpower. If he fails, he moves 2 spaces directly away from this monster and is Immobilized.",
            "Blast: This attack affects all figures adjacent to the target space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Crypt_Dragon",
    },
    {
        name: "Dark Priest",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Brown, Attack: Blue Yellow, Abilities: Action: Dark Prayer, Surge: +1 Heart",
            "Master: Speed: 4, Health: 6, Defense: Brown, Attack: Blue Yellow, Abilities: Action: Dark Prayer, Action: Heal, Surge: +1 Heart",
        ],
        expansion: Expansion.GUARDIANS_OF_DEEPHALL,
        image: "monsters/dark-priest-gd-act1-back.png",
        xws: "darkpriest",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Dark Prayer: Choose 1 hero within 5 spaces of this monster. That hero tests Willpower. If he fails, he suffers 1 Fatigue.",
            "Heal: Choose a monster within 3 spaces of this monster and roll 1 red power die. That monster recovers Hearts equal to the Hearts rolled.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Dark_Priest",
    },
    {
        name: "Dark Priest",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Brown, Attack: Blue Yellow, Abilities: Action: Dark Prayer, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 9, Defense: Brown, Attack: Blue Yellow Yellow, Abilities: Horrifying, Action: Dark Prayer, Action: Heal, Surge: +2 Hearts",
        ],
        expansion: Expansion.GUARDIANS_OF_DEEPHALL,
        image: "monsters/dark-priest-gd-act2-back.png",
        xws: "darkpriest",
        traits: [Trait.CIVILIZED, Trait.CURSED],
        ability_rules: [
            "Horrifying: Each hero adjacent to 1 or more monsters with Horrifying applies -1 to his Willpower (to a minimum of 1).",
            "Dark Prayer: Choose 1 hero within 5 spaces of this monster. That hero tests Willpower. If he fails, he suffers 1 Fatigue.",
            "Heal: Choose a monster within 3 spaces of this monster and roll 1 red power die. That monster recovers Hearts equal to the Hearts rolled.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Dark_Priest",
    },
    {
        name: "Wendigo",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Stealthy, Surge: +1 Heart",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red, Abilities: Freezing, Ravage, Stealthy, Surge: +1 Heart",
        ],
        expansion: Expansion.GUARDIANS_OF_DEEPHALL,
        image: "monsters/wendigo-gd-act1-back.png",
        xws: "wendigo",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Wendigo",
    },
    {
        name: "Wendigo",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Stealthy, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 10, Defense: Gray, Attack: Blue Red Yellow, Abilities: Freezing, Ravage, Stealthy, Surge: +2 Hearts",
        ],
        expansion: Expansion.GUARDIANS_OF_DEEPHALL,
        image: "monsters/wendigo-gd-act2-back.png",
        xws: "wendigo",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Wendigo",
    },
    {
        name: "Manticore",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Ravage, Surge: Pierce 2, Surge: +1 Range",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Yellow, Abilities: Ravage, Surge: Pierce 3, Surge: Poison, Surge: +1 Range",
        ],
        expansion: Expansion.VISIONS_OF_DAWN,
        image: "monsters/manticore-vd-act1-back.png",
        xws: "manticore",
        traits: [Trait.WILDERNESS, Trait.DARK],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Manticore",
    },
    {
        name: "Manticore",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Ravage, Surge: Pierce 3, Surge: +2 Range",
            "Master: Speed: 4, Health: 9, Defense: Gray, Attack: Blue Yellow Yellow, Abilities: Ravage, Surge: Pierce 4, Surge: Poison, Surge: +2 Range",
        ],
        expansion: Expansion.VISIONS_OF_DAWN,
        image: "monsters/manticore-vd-act2-back.png",
        xws: "manticore",
        traits: [Trait.WILDERNESS, Trait.DARK],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Manticore",
    },
    {
        name: "Ogre",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 6, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Knockback, Surge: +3 Hearts",
            "Master: Speed: 3, Health: 9, Defense: Gray, Attack: Blue Red, Abilities: Fleshmonger, Surge: Knockback, Surge: +3 Hearts",
        ],
        expansion: Expansion.VISIONS_OF_DAWN,
        image: "monsters/ogre-vd-act1-back.png",
        xws: "ogre",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Fleshmonger: Each time a hero whose hero token is not on this card suffers 1 or more Hearts from an attack performed by this monster, place 1 of his hero tokens on this card. For each hero token on this card, this monster applies +2 to its Health. When this monster is defeated, discard all hero tokens from this card.",
            "Knockback: Remove the target from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ogre",
    },
    {
        name: "Ogre",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 9, Defense: Gray, Attack: Blue Yellow, Abilities: Surge: Knockback, Surge: +3 Hearts",
            "Master: Speed: 3, Health: 12, Defense: Gray, Attack: Blue Red Yellow, Abilities: Fleshmonger, Surge: Knockback, Surge: +3 Hearts",
        ],
        expansion: Expansion.VISIONS_OF_DAWN,
        image: "monsters/ogre-vd-act2-back.png",
        xws: "ogre",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Fleshmonger: Each time a hero whose hero token is not on this card suffers 1 or more Hearts from an attack performed by this monster, place 1 of his hero tokens on this card. For each hero token on this card, this monster applies +2 to its Health. When this monster is defeated, discard all hero tokens from this card.",
            "Knockback: Remove the target from the map, then place him on any empty space within 3 spaces of his original space. He counts as entering that space.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Ogre",
    },
    {
        name: "Troll",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Gray, Attack: Blue Red, Abilities: Backswing, Reach",
            "Master: Speed: 3, Health: 10, Defense: Gray, Attack: Blue Red, Abilities: Backswing, Reach, Action: Sweep",
        ],
        expansion: Expansion.VISIONS_OF_DAWN,
        image: "monsters/troll-vd-act1-back.png",
        xws: "troll",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Backswing: Use immediately after performing an attack to choose any number of figures affected by that attack. Each of those figures tests Awareness. If none of the figures pass, each chose figure suffers 2 Hearts and is Stunned.",
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Sweep: Perform an attack. This attack effects each figure within 2 spaces and line of sight of this monster. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Troll",
    },
    {
        name: "Troll",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 10, Defense: Gray, Attack: Blue Red Red, Abilities: Backswing, Reach",
            "Master: Speed: 3, Health: 13, Defense: Gray, Attack: Blue Red Red, Abilities: Backswing, Reach, Action: Sweep",
        ],
        expansion: Expansion.VISIONS_OF_DAWN,
        image: "monsters/troll-vd-act2-back.png",
        xws: "troll",
        traits: [Trait.MOUNTAIN, Trait.CAVE],
        ability_rules: [
            "Backswing: Use immediately after performing an attack to choose any number of figures affected by that attack. Each of those figures tests Awareness. If none of the figures pass, each chose figure suffers 2 Hearts and is Stunned.",
            "Reach: This monster may attack targets up to 2 spaces away.",
            "Sweep: Perform an attack. This attack effects each figure within 2 spaces and line of sight of this monster. Each figure rolls defense dice separately.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Troll",
    },
    {
        name: "Deep Elf",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 7, Defense: Brown, Attack: Blue Yellow, Abilities: Stealthy, Pierce 2, Surge: +1 Heart",
            "Master: Speed: 5, Health: 9, Defense: Brown, Attack: Blue Yellow, Abilities: Riposte, Stealthy, Pierce 3, Surge: +1 Heart",
        ],
        expansion: Expansion.BONDS_OF_THE_WILD,
        image: "monsters/deep-elf-bw-act1-back.png",
        xws: "deepelf",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Riposte: Each time an adjacent figure resolves an attack that affects this monster, that figure suffers Hearts equal to the defense results; if the attack is a miss, the figure suffers Hearts equal to the Hearts rolled instead.",
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Deep_Elf",
    },
    {
        name: "Deep Elf",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 8, Defense: Gray, Attack: Blue Yellow, Abilities: Stealthy, Pierce 2, Surge: +2 Hearts",
            "Master: Speed: 5, Health: 10, Defense: Gray, Attack: Blue Yellow, Abilities: Riposte, Stealthy, Pierce 4, Surge: +2 Hearts",
        ],
        expansion: Expansion.BONDS_OF_THE_WILD,
        image: "monsters/deep-elf-bw-act2-back.png",
        xws: "deepelf",
        traits: [Trait.DARK, Trait.CAVE],
        ability_rules: [
            "Riposte: Each time an adjacent figure resolves an attack that affects this monster, that figure suffers Hearts equal to the defense results; if the attack is a miss, the figure suffers Hearts equal to the Hearts rolled instead.",
            "Stealthy: Each attack that targets this monster must roll 3 additional range beyond the normally required amount or the attack is a miss.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Deep_Elf",
    },
    {
        name: "Hellhound",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Surge: Hunt, Surge: Pierce 2",
            "Master: Speed: 4, Health: 6, Defense: Gray, Attack: Blue Red, Abilities: Surge: Hunt, Surge: Fire Breath, Surge: Pierce 2",
        ],
        expansion: Expansion.BONDS_OF_THE_WILD,
        image: "monsters/hellhound-bw-act1-back.png",
        xws: "hellhound",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Hunt: After this attack resolves, you may remove the target from the map and place it in an empty space adjacent to this monster.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Hellhound",
    },
    {
        name: "Hellhound",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 6, Defense: Black, Attack: Blue Red, Abilities: Surge: Hunt, Surge: Pierce 3",
            "Master: Speed: 5, Health: 8, Defense: Black, Attack: Blue Red Yellow, Abilities: Surge: Hunt, Surge: Fire Breath, Surge: Pierce 3",
        ],
        expansion: Expansion.BONDS_OF_THE_WILD,
        image: "monsters/hellhound-bw-act2-back.png",
        xws: "hellhound",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Hunt: After this attack resolves, you may remove the target from the map and place it in an empty space adjacent to this monster.",
            "Fire Breath: Starting with the target space, trace a path of 4 spaces in any direction. All figures on this path are affected by this attack. Each figure rolls defense dice separately.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Hellhound",
    },
    {
        name: "Kobold",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 3, Health: 2, Defense: Brown, Attack: Blue, Abilities: Scamper, Small Beginnings, Surge: Swarm",
            "Master: Speed: 3, Health: 5, Defense: Brown, Attack: Blue Yellow, Abilities: Scamper, Spawner, Surge: Swarm",
        ],
        expansion: Expansion.BONDS_OF_THE_WILD,
        image: "monsters/kobold-bw-act1-back.png",
        xws: "kobold",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Scamper: This monster may move through spaces containing heroes.",
            "Small Beginnings: Do not place this monster during Setup.",
            "Spawner: At the start of each overlord turn, place 1 minion Kobold adjacent to this monster, respecting group limits.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 4,2", "3 Heroes: 8,2", "4 Heroes: 9,3"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Kobold",
    },
    {
        name: "Kobold",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Brown, Attack: Blue, Abilities: Scamper, Small Beginnings, Surge: Swarm",
            "Master: Speed: 4, Health: 7, Defense: Brown, Attack: Blue Yellow, Abilities: Scamper, Spawner, Surge: Swarm",
        ],
        expansion: Expansion.BONDS_OF_THE_WILD,
        image: "monsters/kobold-bw-act2-back.png",
        xws: "kobold",
        traits: [Trait.BUILDING, Trait.CAVE],
        ability_rules: [
            "Scamper: This monster may move through spaces containing heroes.",
            "Small Beginnings: Do not place this monster during Setup.",
            "Spawner: At the start of each overlord turn, place 1 minion Kobold adjacent to this monster, respecting group limits.",
            "Swarm: This monster deals +1 Heart for each other monster adjacent to the target.",
        ],
        group_size: ["2 Heroes: 4,2", "3 Heroes: 8,2", "4 Heroes: 9,3"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Kobold",
    },
    {
        name: "Crow Hag",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Yellow, Abilities: Lifethirst 1, Surge: +1 Heart",
            "Master: Speed: 4, Health: 7, Defense: Black, Attack: Blue Yellow, Abilities: Lifethirst 1, Action: Death Omen, Surge: +1 Heart",
        ],
        expansion: Expansion.TREATY_OF_CHAMPIONS,
        image: "monsters/crow-hag-tc-act1-back.png",
        xws: "crowhag",
        traits: [Trait.DARK, Trait.CIVILIZED],
        ability_rules: [
            "Lifethirst X: Each time a hero within 5 spaces of this monster recovers 1 or more Hearts, that hero reduces the amount of Hearts recovered by X (to a minimum of 0).",
            "Death Omen: Choose 1 hero in this monster's line of sight. That hero may choose to suffer 2 Hearts. If he does not, he suffers 1 condition of your choice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Crow_Hag",
    },
    {
        name: "Crow Hag",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 5, Health: 7, Defense: Black, Attack: Blue Yellow, Abilities: Lifethirst 1, Surge: +2 Hearts",
            "Master: Speed: 5, Health: 9, Defense: Black Gray, Attack: Blue Yellow, Abilities: Lifethirst 2, Action: Death Omen, Surge: +2 Hearts",
        ],
        expansion: Expansion.TREATY_OF_CHAMPIONS,
        image: "monsters/crow-hag-tc-act2-back.png",
        xws: "crowhag",
        traits: [Trait.DARK, Trait.CIVILIZED],
        ability_rules: [
            "Lifethirst X: Each time a hero within 5 spaces of this monster recovers 1 or more Hearts, that hero reduces the amount of Hearts recovered by X (to a minimum of 0).",
            "Death Omen: Choose 1 hero in this monster's line of sight. That hero may choose to suffer 2 Hearts. If he does not, he suffers 1 condition of your choice.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Crow_Hag",
    },
    {
        name: "Demon Lord",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 6, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Sorcery 2, Surge: Wither",
            "Master: Speed: 3, Health: 9, Defense: Gray Gray, Attack: Blue Yellow, Abilities: Aura 1, Sorcery 3, Surge: Wither",
        ],
        expansion: Expansion.TREATY_OF_CHAMPIONS,
        image: "monsters/demon-lord-tc-act1-back.png",
        xws: "demonlord",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Aura X: Each time a hero enters a space adjacent to this monster, that hero suffers X Hearts.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Wither: The target suffers 1 Fatigue.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Demon_Lord",
    },
    {
        name: "Demon Lord",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 3, Health: 8, Defense: Gray Gray, Attack: Blue Yellow Yellow, Abilities: Sorcery 2, Surge: Wither",
            "Master: Speed: 3, Health: 12, Defense: Gray Gray, Attack: Blue Red Yellow, Abilities: Aura 1, Sorcery 3, Surge: Wither",
        ],
        expansion: Expansion.TREATY_OF_CHAMPIONS,
        image: "monsters/demon-lord-tc-act2-back.png",
        xws: "demonlord",
        traits: [Trait.HOT, Trait.CURSED],
        ability_rules: [
            "Aura 1: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Heart.",
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Wither: That target suffers 1 Fatigue.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Demon_Lord",
    },
    {
        name: "Skeleton Archer",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 3, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 1, Reanimation, Surge: +1 Range",
            "Master: Speed: 4, Health: 6, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 1, Reanimation, Surge: +1 Range, Surge: +1 Heart",
        ],
        expansion: Expansion.TREATY_OF_CHAMPIONS,
        image: "monsters/skeleton-archer-tc-act1-back.png",
        xws: "skeletonarcher",
        traits: [Trait.CURSED, Trait.CIVILIZED],
        ability_rules: [
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Reanimation: Each time this monster suffers Hearts and is not defeated, it recovers Hearts equal to either the amount suffered or the number of monsters from this monster group within 3 spaces of it, whichever is less.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Shambling_Colossus",
    },
    {
        name: "Skeleton Archer",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 1, Reanimation, Surge: +2 Range, Surge: +1 Heart",
            "Master: Speed: 4, Health: 8, Defense: Brown, Attack: Blue Yellow, Abilities: Pierce 2, Reanimation, Surge: +2 Range, Surge: +2 Hearts",
        ],
        expansion: Expansion.TREATY_OF_CHAMPIONS,
        image: "monsters/skeleton-archer-tc-act2-back.png",
        xws: "skeletonarcher",
        traits: [Trait.CURSED, Trait.CIVILIZED],
        ability_rules: [
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
            "Reanimation: Each time this monster suffers Hearts and is not defeated, it recovers Hearts equal to either the amount suffered or the number of monsters from this monster group within 3 spaces of it, whichever is less.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.THREE,
        link: "https://descent2e.fandom.com/wiki/Shambling_Colossus",
    },
    {
        name: "Blood Ape",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Surge: +1 Heart",
            "Master: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red, Abilities: Ravage, Action: Leap Attack, Surge: +2 Hearts",
        ],
        expansion: Expansion.STEWARDS_OF_THE_SECRET,
        image: "monsters/blood-ape-ss-act1-back.png",
        xws: "bloodape",
        traits: [Trait.CAVE, Trait.HOT],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Leap Attack: This monster moves up to its Speed. During this movement, it may move through spaces containing enemy figures. Then, perform an attack that affects each figure this monster moved through during this action.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Blood_Ape",
    },
    {
        name: "Blood Ape",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Gray, Attack: Blue Red Yellow, Abilities: Ravage, Surge: +2 Hearts",
            "Master: Speed: 4, Health: 9, Defense: Gray, Attack: Blue Red Red, Abilities: Ravage, Action: Leap Attack, Surge: +2 Hearts",
        ],
        expansion: Expansion.STEWARDS_OF_THE_SECRET,
        image: "monsters/blood-ape-ss-act2-back.png",
        xws: "bloodape",
        traits: [Trait.CAVE, Trait.HOT],
        ability_rules: [
            "Ravage: Both of this monster's actions on a turn may be attack actions.",
            "Leap Attack: This monster moves up to its Speed. During this movement, it may move through spaces containing enemy figures. Then, perform an attack that affects each figure this monster moved through during this action.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Blood_Ape",
    },
    {
        name: "Ferrox",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 4, Defense: Gray, Attack: Blue Red, Abilities: Surge: Disease, Surge: Pierce 2",
            "Master: Speed: 4, Health: 5, Defense: Gray, Attack: Blue Red, Abilities: Action: Extract, Surge: Disease, Surge: Pierce 2",
        ],
        expansion: Expansion.STEWARDS_OF_THE_SECRET,
        image: "monsters/ferrox-ss-act1-back.png",
        xws: "ferrox",
        traits: [Trait.CAVE, Trait.WATER],
        ability_rules: [
            "Extract: Choose a hero adjacent to this monster. That hero tests Might. If he fails, that hero suffers 2 Fatigue, and this monster recovers 2 Hearts.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ferrox",
    },
    {
        name: "Ferrox",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Gray Brown, Attack: Blue Red Yellow, Abilities: Surge: Disease, Surge: Pierce 3",
            "Master: Speed: 4, Health: 8, Defense: Gray Brown, Attack: Blue Red Yellow, Abilities: Action: Extract, Surge: Disease, Surge: Pierce 3",
        ],
        expansion: Expansion.STEWARDS_OF_THE_SECRET,
        image: "monsters/ferrox-ss-act2-back.png",
        xws: "ferrox",
        traits: [Trait.CAVE, Trait.WATER],
        ability_rules: [
            "Extract: Choose a hero adjacent to this monster. That hero tests Might. If he fails, that hero suffers 2 Fatigue, and this monster recovers 2 Hearts.",
            "Disease: If this attack deals at least 1 Heart (after the defense roll), the target is Diseased.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,1", "3 Heroes: 2,1", "4 Heroes: 3,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ferrox",
    },
    {
        name: "Naga",
        act: "I",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 5, Defense: Black, Attack: Blue Red, Abilities: Sorcery 1, Surge: Poison",
            "Master: Speed: 4, Health: 6, Defense: Black, Attack: Blue Red, Abilities: Sorcery 2, Action: Constrict, Surge: Poison",
        ],
        expansion: Expansion.STEWARDS_OF_THE_SECRET,
        image: "monsters/naga-ss-act1-back.png",
        xws: "naga",
        traits: [Trait.WATER, Trait.CAVE],
        ability_rules: [
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Constrict: Choose 1 hero adjacent to this monster. That hero tests Might. If he fails, he is Immobilized, this monster may move 1 space, and then you may place the hero in an empty space adjacent to this monster.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Naga",
    },
    {
        name: "Naga",
        act: "II",
        attack: "Range",
        characteristics: [
            "Minion: Speed: 4, Health: 6, Defense: Black, Attack: Blue Red, Abilities: Sorcery 3, Surge: Poison",
            "Master: Speed: 4, Health: 8, Defense: Black, Attack: Blue Red Yellow, Abilities: Sorcery 3, Action: Constrict, Surge: Poison",
        ],
        expansion: Expansion.STEWARDS_OF_THE_SECRET,
        image: "monsters/naga-ss-act2-back.png",
        xws: "naga",
        traits: [Trait.WATER, Trait.CAVE],
        ability_rules: [
            "Sorcery X: After making an attack roll, this monster may convert up to X range to Hearts, or up to X Hearts to range.",
            "Constrict: Choose 1 hero adjacent to this monster. That hero tests Might. If he fails, he is Immobilized, this monster may move 1 space, and then you may place the hero in an empty space adjacent to this monster.",
            "Poison: If this attack deals at least 1 Heart (after the defense roll), the target is Poisoned.",
        ],
        group_size: ["2 Heroes: 0,1", "3 Heroes: 1,1", "4 Heroes: 2,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Naga",
    },
    {
        name: "Dark Minotaur",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 8, Defense: Brown, Attack: Blue, Abilities: Bull Rush, Filthy Murk, Putrid Boils, Surge: Pierce 1",
            "Master: Speed: 4, Health: 8, Defense: Black, Attack: Blue, Abilities: Bull Rush, Filthy Murk, Putrid Boils, Surge: Pierce 2",
        ],
        expansion: Expansion.SHARDS_OF_EVERDARK,
        image: "monsters/dark-minotaur-se-act1-back.png",
        xws: "darkminotaur",
        traits: [Trait.CIVILIZED, Trait.DARK],
        ability_rules: [
            "Bull Rush: Each time this monster targets a space that it was not adjacent to at the start of its activation, add 1 red power die to its attack pool.",
            "Filthy Murk: At the end of this monster's activation, each hero within 3 spaces of it is Diseased.",
            "Putrid Boils: Each time a Diseased hero within 3 spaces of 1 or more monsters with Putrid Boils voluntarily suffers 1 or more Fatigue, that hero suffers 1 Heart.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Dark_Minotaur",
    },
    {
        name: "Dark Minotaur",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 10, Defense: Gray Brown, Attack: Blue Red, Abilities: Bull Rush, Filthy Murk, Putrid Boils, Surge: Pierce 2",
            "Master: Speed: 4, Health: 10, Defense: Gray Black, Attack: Blue Red, Abilities: Bull Rush, Filthy Murk, Putrid Boils, Surge: Pierce 4",
        ],
        expansion: Expansion.SHARDS_OF_EVERDARK,
        image: "monsters/dark-minotaur-se-act2-back.png",
        xws: "darkminotaur",
        traits: [Trait.CIVILIZED, Trait.DARK],
        ability_rules: [
            "Bull Rush: Each time this monster targets a space that it was not adjacent to at the start of its activation, add 1 red power die to its attack pool.",
            "Filthy Murk: At the end of this monster's activation, each hero within 3 spaces of it is Diseased.",
            "Putrid Boils: Each time a Diseased hero within 3 spaces of 1 or more monsters with Putrid Boils voluntarily suffers 1 or more Fatigue, that hero suffers 1 Heart.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.ONE,
        link: "https://descent2e.fandom.com/wiki/Dark_Minotaur",
    },
    {
        name: "Ice Wyrm",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 7, Defense: Gray Gray, Attack: Blue Red, Abilities: Entomb, Reach",
            "Master: Speed: 4, Health: 9, Defense: Gray Gray, Attack: Blue Red, Abilities: Entomb, Freezing, Reach",
        ],
        expansion: Expansion.SHARDS_OF_EVERDARK,
        image: "monsters/ice-wyrm-se-act1-back.png",
        xws: "icewyrm",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Entomb: Knocked-out heroes within 1 space of this monster can only recover Hearts from stand up actions and heroic feats.",
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Reach: This monster may attack targets up to 2 spaces away.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ice_Wyrm",
    },
    {
        name: "Ice Wyrm",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 11, Defense: Gray Gray, Attack: Blue Red Red, Abilities: Entomb, Reach",
            "Master: Speed: 4, Health: 14, Defense: Gray Gray, Attack: Blue Red Red, Abilities: Entomb, Freezing, Reach",
        ],
        expansion: Expansion.SHARDS_OF_EVERDARK,
        image: "monsters/ice-wyrm-se-act2-back.png",
        xws: "icewyrm",
        traits: [Trait.COLD, Trait.CAVE],
        ability_rules: [
            "Entomb: Knocked-out heroes within 1 space of this monster can only recover Hearts from stand up actions and heroic feats.",
            "Freezing: Each time a hero enters a space adjacent to this monster, that hero suffers 1 Fatigue.",
            "Reach: This monster may attack targets up to 2 spaces away.",
        ],
        group_size: ["2 Heroes: 1,0", "3 Heroes: 0,1", "4 Heroes: 1,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Ice_Wyrm",
    },
    {
        name: "Shade",
        act: "I",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 4, Health: 2, Defense: Black, Attack: Blue Yellow, Abilities: Soul Shackle 1, Action: Flicker, Surge: Pierce 1",
            "Master: Speed: 4, Health: 5, Defense: Black, Attack: Blue Yellow, Abilities: Soul Shackle 2, Action: Flicker, Surge: Pierce 2",
        ],
        expansion: Expansion.SHARDS_OF_EVERDARK,
        image: "monsters/shade-se-act1-back.png",
        xws: "shade",
        traits: [Trait.CURSED, Trait.DARK],
        ability_rules: [
            "Soul Shackle X: Each time a hero within 3 spaces of this monster recovers 1 or more Fatigue, this monster may suffer X Hearts to reduce the amount of Fatigue recovered by X (to a minimum of 0).",
            "Flicker: Choose 1 hero within 3 spaces of this monster. Remove this monster from the map and place it adjacent to that hero. Then, if this monster has not performed an attack this activation, that hero tests Awareness. If he fails, perform an attack with this monster that targets that hero.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Shade",
    },
    {
        name: "Shade",
        act: "II",
        attack: "Melee",
        characteristics: [
            "Minion: Speed: 5, Health: 4, Defense: Black, Attack: Blue Red, Abilities: Soul Shackle 1, Action: Flicker, Surge: Pierce 1",
            "Master: Speed: 5, Health: 7, Defense: Black, Attack: Blue Red Yellow, Abilities: Soul Shackle 3, Action: Flicker, Surge: Pierce 2",
        ],
        expansion: Expansion.SHARDS_OF_EVERDARK,
        image: "monsters/shade-se-act2-back.png",
        xws: "shade",
        traits: [Trait.CURSED, Trait.DARK],
        ability_rules: [
            "Soul Shackle X: Each time a hero within 3 spaces of this monster recovers 1 or more Fatigue, this monster may suffer X Hearts to reduce the amount of Fatigue recovered by X (to a minimum of 0).",
            "Flicker: Choose 1 hero within 3 spaces of this monster. Remove this monster from the map and place it adjacent to that hero. Then, if this monster has not performed an attack this activation, that hero tests Awareness. If he fails, perform an attack with this monster that targets that hero.",
            "Pierce X: This attack ignores X Shields rolled on the defense dice.",
        ],
        group_size: ["2 Heroes: 2,1", "3 Heroes: 3,1", "4 Heroes: 4,1"],
        tier: Tier.TWO,
        link: "https://descent2e.fandom.com/wiki/Shade",
    },
];
