export var Tier;
(function (Tier) {
    Tier["ONE"] = "Tier One";
    Tier["TWO"] = "Tier Two";
    Tier["THREE"] = "Tier Three";
})(Tier || (Tier = {}));
export var Expansion;
(function (Expansion) {
    Expansion["BASE_GAME"] = "Base Game";
    Expansion["CONVERSION_KIT"] = "Conversion Kit";
    Expansion["LAIR_OF_THE_WRYM"] = "Lair Of The Wyrm";
    Expansion["LABYRINTH_OF_RUIN"] = "Labyrinth Of Ruin";
    Expansion["THE_TROLLFENS"] = "The Trollfens";
    Expansion["SHADOW_OF_NEREKHALL"] = "Shadow Of Nerekhall";
    Expansion["MANOR_OF_RAVENS"] = "Manor Of Ravens";
    Expansion["MISTS_OF_BILEHALL"] = "Mists Of Bilehall";
    Expansion["THE_CHAINS_THAT_RUST"] = "The Chains That Rust";
    Expansion["OATH_OF_THE_OUTCAST"] = "Oath Of The Outcast";
    Expansion["CROWN_OF_DESTINY"] = "Crown Of Destiny";
    Expansion["CRUSADE_OF_THE_FORGOTTEN"] = "Crusade Of The Forgotten";
    Expansion["GUARDIANS_OF_DEEPHALL"] = "Guardians Of Deephall";
    Expansion["VISIONS_OF_DAWN"] = "Visions Of Dawn";
    Expansion["BONDS_OF_THE_WILD"] = "Bonds Of The Wild";
    Expansion["TREATY_OF_CHAMPIONS"] = "Treaty Of Champions";
    Expansion["STEWARDS_OF_THE_SECRET"] = "Stewards Of The Secret";
    Expansion["SHARDS_OF_EVERDARK"] = "Shards Of Everdark";
})(Expansion || (Expansion = {}));
export var Trait;
(function (Trait) {
    Trait["WILDERNESS"] = "Wilderness";
    Trait["DARK"] = "Dark";
    Trait["CAVE"] = "Cave";
    Trait["COLD"] = "Cold";
    Trait["HOT"] = "Hot";
    Trait["MOUNTAIN"] = "Mountain";
    Trait["CURSED"] = "Cursed";
    Trait["CIVILIZED"] = "Civilized";
    Trait["BUILDING"] = "Building";
    Trait["WATER"] = "Water";
})(Trait || (Trait = {}));
